import { useAppSelector } from "@/store/store-hooks";
import {
  OrthoFrustum,
  computeOrthoFrustum,
  parseVector3,
  selectIElementWorldTransform,
  selectIElementsWorldPosition,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
  isIElementGenericImgSheet,
} from "@faro-lotv/ielement-types";
import { Z_TO_Y_UP_QUAT } from "@faro-lotv/project-source";
import { Vector3 as Vector3Prop, useThree } from "@react-three/fiber";
import { isEqual } from "es-toolkit";
import { useMemo } from "react";
import {
  Box3,
  OrthographicCamera,
  Quaternion,
  Vector3,
  Vector3Tuple,
  Vector4,
} from "three";
import { useSheetCorners } from "./use-sheet-corners";

/** Information needed to center a camera on a set of placeholders */
export type CameraCenterData = {
  /** The camera position */
  position: Vector3;

  /** The camera quaternion */
  quaternion: Quaternion;

  /** The Orthographic frustum to make all placeholders visible */
  frustum: OrthoFrustum;
};

/**
 * Update a camera so it will frame a scene based on the centering data passed
 *
 * @param camera The ortho camera we want to update
 * @param data The data about the scene we want to frame
 */
export function centerOrthoCamera(
  camera: OrthographicCamera,
  data: CameraCenterData,
): void {
  camera.position.copy(data.position);
  camera.quaternion.copy(data.quaternion);
  // As we assign the frustrum we need to put manual to true
  Object.assign(camera, { ...data.frustum, manual: true });
  camera.updateProjectionMatrix();
}

export type CenterCameraOnPlaceholdersProps = {
  /** The sheet for which we want to compute the camera configuration */
  sheetElement?: IElementGenericImgSheet;

  /** All the placeholders */
  placeholders: IElementImg360[];

  /** aspect ration for view (if not provided full view will be used) */
  viewAspectRatio?: number;

  /**
   * Factor to modify the zoom of the camera relative to the bounds of @see cameraAlignedWaypoints
   * by default Add a 30% padding so that all elements are properly visible.
   */
  paddingFactor?: number;

  /**  An optional camera position to use instead of centering the placeholders */
  cameraPosition?: Vector3Prop;

  /** an optional parameter to express the minimum frustum height that the ortho camera should have  */
  minFrustumHeight?: number;
};

/**
 * Compute the correct position and frustum to visualize a set of objects on an ortho camera
 *
 * @returns the data needed to center the camera
 */
export function useCenterCameraOnPlaceholders({
  sheetElement,
  placeholders,
  viewAspectRatio,
  paddingFactor = 1.3,
  cameraPosition,
  minFrustumHeight,
}: CenterCameraOnPlaceholdersProps): CameraCenterData {
  const { position: sheetPosition, quaternion: sheetQuaternion } =
    useAppSelector(selectIElementWorldTransform(sheetElement?.id));

  // Get the id of each panorama
  const ids = placeholders.map((iElement) => iElement.id);

  // Compute the center/rotation needed to align the points to the camera reference system
  // to rotate the ortho camera along the sheet rotation and not along the world CS.
  // In the Sphere XG viewer, all sheets have a local reference system that is Z-up,
  // right handed. However, the world CS is Y-up. Therefore, when computing the rotation
  // from the sheet "orientation" to the word "orientation", we also premultiply by the
  // Z to Y up quaternion.
  const offset = new Vector3().fromArray(sheetPosition);
  const rotation = Z_TO_Y_UP_QUAT.clone().multiply(
    new Quaternion().fromArray(sheetQuaternion),
  );
  const rotationInv = rotation.clone().invert();

  const cameraAlignedWaypoints = useRotatedPoints(
    useAppSelector(selectIElementsWorldPosition(ids), isEqual),
    offset,
    rotationInv,
  );

  const worldBox = useMemo(() => {
    const TEMP_P = new Vector3();
    const box = new Box3();
    cameraAlignedWaypoints.forEach((p) =>
      box.expandByPoint(TEMP_P.set(p[0], p[1], p[2])),
    );
    return box;
  }, [cameraAlignedWaypoints]);

  // Compute the quaternion we want for the camera so we look down on Y
  // but rotating the camera so the sheet is straight
  const quaternion = useMemo(() => {
    return sheetElement
      ? new Quaternion().fromArray(sheetQuaternion)
      : Z_TO_Y_UP_QUAT.clone();
  }, [sheetElement, sheetQuaternion]);

  // If there are no placeholders, we add the corners of the sheet
  const cameraAlignedSheetCorners = useRotatedPoints(
    useSheetCorners(sheetElement),
    offset,
    rotationInv,
  );

  // Get screen aspect ratio
  const aspectRatio = useThree((state) => {
    if (viewAspectRatio) return viewAspectRatio;

    const viewport = state.gl.getViewport(new Vector4());
    return viewport.width / viewport.height;
  });

  const hasValidWorldBox =
    cameraAlignedWaypoints.length > 0 &&
    worldBox.max.x - worldBox.min.x > Number.EPSILON &&
    worldBox.max.z - worldBox.min.z > Number.EPSILON;

  const hasValidSheet =
    !!sheetElement && isIElementGenericImgSheet(sheetElement);

  // Do not use the tiled img sheet corners if there are scans available
  // as they are not representative of the area of interest due to noise and the tiling algorithm
  const shouldUseSheetCorners = !hasValidWorldBox && hasValidSheet;

  const orthoFrustumPoints = [
    ...cameraAlignedWaypoints,
    ...(shouldUseSheetCorners ? cameraAlignedSheetCorners : []),
  ];

  // If there are no waypoints and no sheet center around the origin
  if (orthoFrustumPoints.length === 0) {
    orthoFrustumPoints.push([0, 0, 0]);
  }

  /** Y-axis offset for the camera position */
  const Y_OFFSET = 100;
  const orthoFrustum = computeOrthoFrustum(
    orthoFrustumPoints,
    aspectRatio,
    paddingFactor,
  );

  if (
    minFrustumHeight &&
    minFrustumHeight > orthoFrustum.top - orthoFrustum.bottom
  ) {
    // The computation below ensures that the ortho frustum vertical size measures
    // exactly 'minFrustumHeight', while keeping the aspect ratio that the ortho
    // frustum already has.
    const adjust = minFrustumHeight / (orthoFrustum.top - orthoFrustum.bottom);
    orthoFrustum.top *= adjust;
    orthoFrustum.bottom *= adjust;
    orthoFrustum.left *= adjust;
    orthoFrustum.right *= adjust;
  }

  const position = cameraPosition
    ? parseVector3(cameraPosition)
    : orthoFrustum.bboxCenter.sub(offset).applyQuaternion(rotation).add(offset);
  position.y += Y_OFFSET;

  return {
    position,
    frustum: orthoFrustum,
    quaternion,
  };
}

const TEMP_VECTOR = new Vector3();

/**
 * @param points to rotate
 * @param rotationCenter center for rotating the points
 * @param rotation to apply to the points around the center
 * @returns the rotated points
 */
function useRotatedPoints(
  points: Vector3Tuple[],
  rotationCenter: Vector3,
  rotation: Quaternion,
): Vector3Tuple[] {
  return useMemo(
    () =>
      points.map((v) =>
        TEMP_VECTOR.fromArray(v)
          .sub(rotationCenter)
          .applyQuaternion(rotation)
          .add(rotationCenter)
          .toArray(),
      ),
    [points, rotation, rotationCenter],
  );
}
