import { PointCloudObject } from "@/object-cache";
import { AbstractRenderingPolicy } from "@faro-lotv/lotv";
import { debounce } from "es-toolkit/compat";

/**
 * The fraction of points to render in fast-rendering mode.
 *
 * Determined experimentally to ensure acceptable FPS on low-end devices when the camera is moving.
 */
const SUBSAMPLED_RENDER_FRACTION = 0.1;

/** The time to wait for debouncing scene change events, to avoid too many re-renders in a row. */
const SCENE_CHANGE_WAIT_MS = 300;

/** The maximum time to wait before re-rendering after a scene change. */
const SCENE_CHANGE_MAX_DELAY_MS = 700;

/** A rendering policy for rendering many point clouds in the same scene. */
export class MultiCloudRenderingPolicy extends AbstractRenderingPolicy {
  private sceneChangedBuffer = false;

  /**
   * @param pointCloudObjects All point clouds rendered in the scene.
   * @returns A rendering policy for rendering many point clouds in the same scene.
   */
  constructor(
    /** All point clouds rendered in the scene. */
    public pointCloudObjects: PointCloudObject[],
  ) {
    super();

    // Refresh view when new nodes have been loaded
    for (const pointCloud of this.pointCloudObjects) {
      pointCloud.nodeReady.on(this.queueSceneChange);
    }
  }

  /** Queue a scene change, which is debounced to reduce the frequency of scene invalidations and improve FPS. */
  queueSceneChange = debounce(
    () => {
      this.invalidateScene();
    },
    SCENE_CHANGE_WAIT_MS,
    { maxWait: SCENE_CHANGE_MAX_DELAY_MS },
  );

  /** Immediately invalidate the scene, causing a re-render. */
  invalidateScene(): void {
    this.sceneChangedBuffer = true;
  }

  /** @inheritdoc */
  override sceneChanged(): boolean {
    const hasChanged = super.sceneChanged() || this.sceneChangedBuffer;
    if (hasChanged) {
      // Change registered, no need to queue up another one unless there are more changes
      this.queueSceneChange.cancel();
    }
    this.sceneChangedBuffer = false;
    return hasChanged;
  }

  protected modelChanged(): boolean {
    return false;
  }

  /** @inheritdoc */
  override onCameraStartedMoving(): void {
    this.enableFastRendering();
    super.onCameraStartedMoving();
    this.invalidateScene();
  }

  /** @inheritdoc */
  override onCameraStoppedMoving(): void {
    this.disableFastRendering();
    super.onCameraStoppedMoving();
    this.invalidateScene();
  }

  /** Reduce visual quality in favor of better performance. */
  private enableFastRendering(): void {
    for (const pointCloud of this.pointCloudObjects) {
      pointCloud.setSubsampledRenderingFraction(SUBSAMPLED_RENDER_FRACTION);
      pointCloud.setSubsampledRenderingOn(true);
    }
  }

  /** Use high-quality rendering, but with worse performance. */
  private disableFastRendering(): void {
    for (const pointCloud of this.pointCloudObjects) {
      pointCloud.setSubsampledRenderingOn(false);
    }
  }
}
