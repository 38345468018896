import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { WalkSceneActiveElement } from "@/modes/walk-mode/walk-types";
import { useAppSelector } from "@/store/store-hooks";
import { selectObjectVisibility } from "@/store/view-options/view-options-selectors";
import { ViewObjectTypes } from "@/store/view-options/view-options-slice";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { IElementGenericAnnotation } from "@faro-lotv/ielement-types";
import { State } from "@faro-lotv/project-source";
import { isEqual } from "es-toolkit";
import { Material, Matrix4, Object3D, Plane, Vector3 } from "three";
import { GeneralLinkAnnotationRenderer } from "./annotation-renderers/general-link-annotation-renderer";
import { ImageAnnotationRenderer } from "./annotation-renderers/image-annotation-renderer";
import { InfoAnnotationRenderer } from "./annotation-renderers/info-annotation-renderer";
import { MarkupAnnotationRenderer } from "./annotation-renderers/markup-annotation-renderer";
import { AnnotationSorterProvider } from "./annotation-sorter";
import {
  AnnotationProps,
  AnnotationTypes,
  selectAnnotationType,
} from "./annotations-types";

const IDENTITY = new Matrix4();

export type AnnotationsRendererProps = {
  /** The list of annotations to render */
  annotations: IElementGenericAnnotation[];
  /** The world offset applied to the pano associated to these annotations */
  worldTransform?: Matrix4;
  /**  if true, the annotation is not collapsed based on the view */
  preventCollapse?: boolean;
  /**
   * Enable the annotation fading
   *
   * @default true
   */
  fadeOff?: boolean;
  /** Callback executed when the active element should be changed */
  onTargetElementChanged?(element: WalkSceneActiveElement): void;
  /** Whether depth testing should be used to render the annotations */
  depthTest?: Material["depthTest"];
  /** The render order to use for the annotations */
  renderOrder?: Object3D["renderOrder"];
  /** Optional clipping planes */
  clippingPlanes?: Plane[];
};

/** @returns A renderer for the annotations of a panorama image */
export function AnnotationsRenderer({
  annotations,
  worldTransform = IDENTITY,
  preventCollapse,
  fadeOff = true,
  renderOrder,
  depthTest,
  clippingPlanes = [],
  onTargetElementChanged,
}: AnnotationsRendererProps): JSX.Element | null {
  const { canReadAnnotations } = useAnnotationPermissions();
  const shouldAnnotationsBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.annotations),
  );

  const visibleAnnotations = useAppSelector(
    selectVisibleAnnotations(annotations, clippingPlanes, worldTransform),
    isEqual,
  );

  if (!canReadAnnotations || !shouldAnnotationsBeVisible) return null;

  return (
    <AnnotationSorterProvider
      annotations={annotations}
      preventCollapse={preventCollapse}
      fadeOff={fadeOff}
    >
      {visibleAnnotations.map((a) => (
        <AnnotationRenderer
          key={a.id}
          iElement={a}
          worldTransform={worldTransform}
          onTargetElementChanged={onTargetElementChanged}
          depthTest={depthTest}
          renderOrder={renderOrder}
        />
      ))}
    </AnnotationSorterProvider>
  );
}

/**
 * @returns a specific component depending on the type of the annotation. If the annotation is not supported it is not rendered.
 */
function AnnotationRenderer({
  iElement,
  onTargetElementChanged,
  worldTransform,
  depthTest,
  renderOrder,
}: AnnotationProps): JSX.Element | null {
  const annotationType = useAppSelector(selectAnnotationType(iElement));

  switch (annotationType) {
    case AnnotationTypes.info:
      return (
        <InfoAnnotationRenderer
          iElement={iElement}
          worldTransform={worldTransform}
        />
      );
    case AnnotationTypes.image:
      return (
        <ImageAnnotationRenderer
          iElement={iElement}
          worldTransform={worldTransform}
        />
      );
    case AnnotationTypes.generalLink:
      return (
        <GeneralLinkAnnotationRenderer
          iElement={iElement}
          worldTransform={worldTransform}
          onTargetElementChanged={onTargetElementChanged}
        />
      );
    case AnnotationTypes.externalMarkup:
    case AnnotationTypes.markup:
      return (
        <MarkupAnnotationRenderer
          annotation={iElement}
          worldTransform={worldTransform}
          renderOrder={renderOrder}
          depthTest={depthTest}
          isExternalAnnotation={
            annotationType === AnnotationTypes.externalMarkup
          }
        />
      );
    default:
      return null;
  }
}

const M1 = new Matrix4();
const POSITION = new Vector3();

function selectVisibleAnnotations(
  annotations: IElementGenericAnnotation[],
  clippingPlanes: Plane[],
  worldTransform: Matrix4,
) {
  return (state: State) => {
    if (clippingPlanes.length !== 6) {
      return annotations;
    }
    const visibleAnnotations = new Array<IElementGenericAnnotation>();
    for (const annotation of annotations) {
      const pose = selectIElementWorldMatrix4(annotation.id)(state);
      M1.copy(worldTransform).multiply(pose);
      POSITION.setFromMatrixPosition(M1);
      if (isInsideClippingPlanes(POSITION, clippingPlanes)) {
        visibleAnnotations.push(annotation);
      }
    }
    return visibleAnnotations;
  };
}

/**
 *
 * @param point A 3D position
 * @param planes six clipping planes
 * @returns Whether point is inside the clipping box
 */
export function isInsideClippingPlanes(
  point: Vector3,
  planes: Plane[],
): boolean {
  for (let i = 0; i < 6; i++) {
    if (planes[i].distanceToPoint(point) < 0) {
      return false;
    }
  }
  return true;
}
