precision highp float;

varying vec2 vUv;

// Max alpha as determined by the CPU
uniform float uMaxAlpha;
// The screen texture to apply the normalization to
uniform sampler2D uColorTex;

// Whether to display the alpha values for debugging
uniform bool uDebugAlpha;

// Rescales the alpha of the color texture to make the maximum alpha 1.0
void normalizeOpacity() {
    vec4 color = texture2D(uColorTex, vUv);

    // Transparent pixels should stay transparent
    if(color.a < 1e-5) {
        gl_FragColor = color;
        return;
    }

    // float maxAlpha = texture2D(uAlphaTex, vec2(0.0, 0.0)).a;
    float maxAlpha = uMaxAlpha;
    float rescaledAlpha = maxAlpha > 0.0 ? color.a / maxAlpha : 0.0;
    rescaledAlpha = clamp(rescaledAlpha, 0.0, 1.0);
    gl_FragColor = vec4(color.rgb, rescaledAlpha);

    if(uDebugAlpha) {
        if(rescaledAlpha < 1e-5) {
            gl_FragColor = vec4(0, 0, 0, 1);
        } else if(rescaledAlpha > 0.95) {
            gl_FragColor = vec4(1, 0, 0, 1);
        } else if(rescaledAlpha > 0.5) {
            gl_FragColor = vec4(0, 1, 0, (rescaledAlpha - 0.5) * 2.0);
        } else {
            gl_FragColor = vec4(0, 0, 1, (rescaledAlpha * 2.0) + 0.5);
        }
    }
}

void main() {
    normalizeOpacity();
}
