import {
  EventType,
  UploadPointCloudEventProperties,
} from "@/analytics/analytics-events";
import { useIsCurrentAreaLoading } from "@/modes/mode-data-context";
import { PointCloudMetaDialog } from "@/modes/sheet-mode/import-data/point-cloud-meta-dialog";
import { useAppSelector } from "@/store/store-hooks";
import { selectCanUploadPointCloud } from "@/store/subscriptions/subscriptions-selectors";
import {
  computeReferenceSystemProperties,
  fetchProjectIElements,
  selectAllIElementsOfType,
  selectIElement,
  selectNumberOfPointCloudsOnFloor,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { GUID, assert, getFileExtension } from "@faro-lotv/foundation";
import {
  IElementGenericPointCloud,
  IElementSection,
  IElementType,
  IElementTypeHint,
  isIElementSectionWithTypeHint,
  isIElementTimeseriesDataSession,
} from "@faro-lotv/ielement-types";
import { createMutationAddPointCloud } from "@faro-lotv/service-wires";
import { isEqual } from "es-toolkit";
import {
  UpdateProjectParam,
  UploadElementType,
  useUploadElement,
} from "./use-upload-element";

/** The maximum point cloud file size currently supported by the backend */
export const MAX_UPLOAD_CLOUD_FILE_SIZE_GB = 50;

/** The maximum CAD model file size currently supported by the backend */
export const MAX_UPLOAD_MODEL_FILE_SIZE_GB = 2;

/** All supported pointcloud file extensions.  */
export enum SupportedPCFileExtensions {
  laz = "laz",
  e57 = "e57",
  cpe = "cpe",
}

/** All supported orbis file extensions.  */
export enum SupportedOrbisFileExtensions {
  geoslam = "geoslam",
}

/** List of supported pointcloud file extensions but converted to strings */
const supportedPcExtensionList = Object.values(SupportedPCFileExtensions).map(
  (ext) => `${ext}`,
);

/** List of supported pointcloud file extensions but converted to strings */
const supportedOrbisExtensionList = Object.values(
  SupportedOrbisFileExtensions,
).map((ext) => `${ext}`);

/**
 * @param fileName name of the file to check the extension of
 * @returns if the name passed has a supported point cloud extension
 */
export function isSupportedPCFileExtension(fileName: string): boolean {
  const extension = getFileExtension(fileName)?.toLowerCase() ?? "";

  return supportedPcExtensionList.includes(extension);
}

/**
 * @param fileName name of the file to check the extension of
 * @returns if the name passed has a supported orbis extension
 */
export function isSupportedOrbisFileExtension(fileName: string): boolean {
  const extension = getFileExtension(fileName)?.toLowerCase() ?? "";

  return supportedOrbisExtensionList.includes(extension);
}

/** @returns True if the import point cloud feature is available for the current floor */
export function useCanImportPointCloud(): boolean {
  const hasPcmBundle = useAppSelector(selectCanUploadPointCloud);
  const isAppReadyForImport = useIsAppReadyForImport();

  return hasPcmBundle && isAppReadyForImport;
}

/** @returns Whether data imports can be made in the current app state */
export function useIsAppReadyForImport(): boolean {
  return !useIsCurrentAreaLoading();
}

/**
 * Infer the point cloud type from the file name
 *
 * @param fileName selected by the user
 * @returns the IElementType for the PointCloud format matching the file extension
 * @throws an Error if the extension is not one of the valid ones
 */
export function pointCloudTypeFromFileName(
  fileName: string,
): IElementGenericPointCloud["type"] {
  const ext = fileName.toLowerCase().split(".").at(-1);
  switch (ext) {
    case SupportedPCFileExtensions.laz:
      return IElementType.pointCloudLaz;
    case SupportedPCFileExtensions.e57:
      return IElementType.pointCloudE57;
    case SupportedPCFileExtensions.cpe:
      return IElementType.pointCloudCpe;
    case SupportedOrbisFileExtensions.geoslam:
      return IElementType.pointCloudGeoSlam;
  }
  throw new Error(`Can't infer point cloud type from file ${fileName}`);
}

/**
 * Function that sends the AddPointCloud mutation to the project api and update the local copy of the project
 */
export async function updateProjectWithPointCloud({
  appStore,
  dispatch,
  projectApi,
  file,
  name,
  createdAt,
  areaId,
  downloadUrl,
  md5Hash,
  isGeoReferenced,
}: UpdateProjectParam): Promise<void> {
  assert(
    areaId,
    "AreaId for updating project with PointCloud is not specified.",
  );
  const area = selectIElement(areaId)(appStore.getState());
  assert(area, `No area found with ID ${areaId}`);

  // Fetch the time series from the backend, so we can properly
  // add the cloud to an area not yet loaded by the viewer
  const { page: timeSeries } = await projectApi.getIElements({
    types: [IElementType.timeSeries],
    typeHints: [IElementTypeHint.dataSession],
    ancestorIds: [area.id],
  });
  assert(
    timeSeries.length === 0 || isIElementTimeseriesDataSession(timeSeries[0]),
    "Fetching timeseries returned unexpected result",
  );

  const type = pointCloudTypeFromFileName(file.name);

  // Add new PC to the project
  const addPCMutation = createMutationAddPointCloud({
    floorId: area.id,
    name,
    createdAt: createdAt.toISOString(),
    rootId: area.rootId,
    type,
    uri: downloadUrl,
    timeseriesId: timeSeries[0]?.id,
    fileName: file.name,
    fileSize: file.size,
    md5Hash,
    pose: {
      gps: null,
      isWorldRot: false,
      pos: null,
      scale: null,
      // For now expect all PointClouds to be Z-Up so fix them to be Y-Up
      // update this code with a proper rotation when the user will be able to choose
      // the point cloud reference system during upload
      rot: {
        x: Math.SQRT1_2,
        y: 0,
        z: 0,
        w: Math.SQRT1_2,
      },
    },
    refCoordSystemMatrix: computeReferenceSystemProperties(
      { type, typeHint: null },
      false,
    ),
    metaDataMap: { scanPoseMigration: { poTreePoseFixed: true } },
    isGeoReferenced: !!isGeoReferenced,
  });

  await projectApi.applyMutations([addPCMutation]);

  // Fetch the changed sub-tree and update the local copy of the project
  await dispatch(
    fetchProjectIElements({
      fetcher: () =>
        projectApi.getAllIElements({
          // We need to refresh from the floor as it may have changed if a new timeseries have been updated
          ancestorIds: [area.id],
        }),
    }),
  );
}

type PointCloudUploadDialogProps = {
  /** File to upload */
  file: File;

  /** Area parent of the PointCloud element that's going to be created in the project */
  area: IElementSection;

  /** Function that allows to set/change the currently selected PointCloud file in the context */
  setPointCloudFile(file: File | undefined): void;

  /** Function called when the PointCloud file has been uploaded */
  onFileUploaded?(): void;
};

/**
 * @returns a confirmation dialog with parameters to set up the upload of a pointcloud file
 */
export function PointCloudUploadDialog({
  file,
  area,
  setPointCloudFile,
  onFileUploaded,
}: PointCloudUploadDialogProps): JSX.Element {
  const uploadPointCloud = useUploadElement(UploadElementType.pointcloud);

  const allAreaSections = useAppSelector(
    selectAllIElementsOfType((element): element is IElementSection =>
      isIElementSectionWithTypeHint(element, IElementTypeHint.area),
    ),
    isEqual,
  );

  const numberOfPointCloudsOnFloor = useAppSelector(
    selectNumberOfPointCloudsOnFloor(area),
  );

  return (
    <PointCloudMetaDialog
      initialDate={new Date(file.lastModified)}
      initialName={file.name}
      initialFloorplanId={area.id}
      floorplans={allAreaSections}
      onConfirm={(
        pointCloudName: string,
        pointCloudDate: Date,
        areaId: GUID,
        isGeoReferenced: boolean,
      ) => {
        Analytics.track<UploadPointCloudEventProperties>(
          EventType.uploadPointCloud,
          {
            fileSize: file.size,
            extension: getFileExtension(file.name) ?? "",

            numberOfExistingPCs: numberOfPointCloudsOnFloor,

            nameChanged: file.name !== pointCloudName,
            timePointChanged: file.lastModified !== pointCloudDate.getTime(),
            floorChanged: area.id !== areaId,
          },
        );

        uploadPointCloud({
          file,
          areaId,
          name: pointCloudName,
          createdAt: pointCloudDate,
          onFileUploaded,
          isGeoReferenced,
        });

        setPointCloudFile(undefined);
      }}
      onCancel={() => setPointCloudFile(undefined)}
    />
  );
}
