import { NormalizeOpacityPass as LotvNormalizeOpacityPass } from "@faro-lotv/lotv";
import { useState } from "react";
import { attachPass } from "../attach-utils";

export type NoramlizeOpacityPass = {
  /** Whether the pass is enabled */
  enabled?: boolean;
};

/**
 * @returns A EffectPipeline pass that will apply the mip gap filling filter to the entire scene
 */
export function NormalizeOpacityPass({
  enabled = true,
}: NoramlizeOpacityPass): JSX.Element {
  const [pass] = useState(() => new LotvNormalizeOpacityPass());

  return (
    <primitive
      name="NormalizeOpacityPass"
      object={pass}
      attach={attachPass}
      enabled={enabled}
    />
  );
}
