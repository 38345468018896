import { ScanColoring } from "@/data-preparation-tool/store/data-preparation-view-options/data-preparation-view-options-slice";
import { PointCloudObject } from "@/object-cache";
import {
  ColorString,
  dataComparisonColorsSorted,
  neutral,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { useEffect, useMemo } from "react";
import {
  Material,
  Plane,
  PointsMaterial,
  PointsMaterialParameters,
} from "three";

/**
 * @param index The index to get the color for.
 * @param colors the color array used to select color for the given index
 * @returns The comparison color for the given index.
 */
export function colorForIndex(
  index: number,
  colors: ColorString[],
): ColorString {
  return colors[index % colors.length];
}

interface UsePointCloudMaterialsOptions {
  /** The point clouds to adapt the materials for */
  pointClouds: PointCloudObject[];

  /** The IDs of point clouds to highlight. If defined, all non-highlighted point clouds are rendered grey. */
  highlightedIds?: GUID[];

  /** The used scan coloring mode @default color by scan */
  scanColoring?: ScanColoring;

  /**
   * The explicit colors to use to select color for pointClouds.
   *
   * @default dataComparisonColorsSorted
   */
  colors?: ColorString[];

  /** The clipping planes to use for the custom materials */
  clippingPlanes?: Plane[];
}

/**
 * Sets materials with distinct colors and convenient transparency to render multiple point clouds.
 */
export function usePointCloudMaterials({
  pointClouds,
  scanColoring = ScanColoring.byScan,
  highlightedIds,
  colors = dataComparisonColorsSorted,
  clippingPlanes,
}: UsePointCloudMaterialsOptions): void {
  const highlightedSet = useMemo(
    () => new Set(highlightedIds),
    [highlightedIds],
  );

  useEffect(() => {
    const oldMaterials: Material[] = [];

    for (const [index, pointCloud] of pointClouds.entries()) {
      oldMaterials.push(pointCloud.material);

      const isHighlighted =
        highlightedSet.size === 0 || highlightedSet.has(pointCloud.iElement.id);

      const materialParams: PointsMaterialParameters = {
        transparent: true,
        opacity: 0.005,
        precision: "highp",
        depthTest: false,
        depthWrite: false,
        sizeAttenuation: false,
        size: 2,
        clippingPlanes: clippingPlanes ?? undefined,
        clipIntersection: false,
      };

      switch (scanColoring) {
        case ScanColoring.byScan: {
          materialParams.color = isHighlighted
            ? colorForIndex(index, colors)
            : neutral[400];
          break;
        }
        case ScanColoring.originalColor: {
          if (isHighlighted) {
            materialParams.vertexColors = oldMaterials[index].vertexColors;
          } else {
            materialParams.color = neutral[400];
          }
          break;
        }
      }

      pointCloud.material = new PointsMaterial(materialParams);
    }
    return () => {
      for (const [index, pointCloud] of pointClouds.entries()) {
        pointCloud.material.dispose();
        pointCloud.material = oldMaterials[index];
      }
    };
  }, [clippingPlanes, colors, pointClouds, scanColoring, highlightedSet]);
}
