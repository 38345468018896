import { curryAppSelector } from "@/store/reselect";
import { RootState } from "@/store/store";
import { EMPTY_ARRAY, GUID } from "@faro-lotv/foundation";
import {
  IElementGenericPointCloudStream,
  isValid,
} from "@faro-lotv/ielement-types";
import { isRevisionScanEntity } from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import { shallowEqual } from "react-redux";
import {
  selectPointCloudStreamForScanEntity,
  selectRevisionEntity,
  selectRevisionEntityAllDescendants,
} from "../revision-selectors";

/**
 * @returns the selected entities' id in the scan tree
 * @param state current app state
 */
export function selectSelectedEntityId(state: RootState): GUID | undefined {
  return state.dataPreparationUi.selectedEntityId;
}

/**
 * @returns all point cloud streams for and beneath the given entity
 * @param id the entity to get the point cloud streams for
 */
export function selectPointCloudStreamsForEntity(id?: GUID) {
  return (state: RootState): IElementGenericPointCloudStream[] => {
    if (!id) {
      return EMPTY_ARRAY;
    }

    const entity = selectRevisionEntity(id)(state);

    if (!entity) {
      return EMPTY_ARRAY;
    }

    const children = selectRevisionEntityAllDescendants(entity.id)(state);

    return [entity, ...children]
      .filter(isRevisionScanEntity)
      .map((e) => selectPointCloudStreamForScanEntity(e)(state))
      .filter(isValid);
  };
}

/**
 * @returns all point cloud streams for and beneath the given entity
 * @param id the entity to get the point cloud streams for
 */
function selectPointCloudStreamIdsForEntity(id?: GUID) {
  return (state: RootState): GUID[] => {
    return selectPointCloudStreamsForEntity(id)(state).map((pcs) => pcs.id);
  };
}

/**
 * @returns all point cloud streams for the selected entity
 * @param state current app state
 */
export const selectPointCloudStreamIdsForSelectedEntity = createSelector(
  [(state: RootState) => state],
  (state: RootState): GUID[] | undefined => {
    if (!state.dataPreparationUi.selectedEntityId) {
      return;
    }

    return selectPointCloudStreamIdsForEntity(
      state.dataPreparationUi.selectedEntityId,
    )(state);
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } },
);

/**
 * @param state current app state
 * @returns The ID of the entity that is currently being hovered or `undefined` otherwise.
 */
function selectHoveredEntityId(state: RootState): GUID | undefined {
  return state.dataPreparationUi.hoveredEntityId;
}

/**
 * @returns true if the given entity is being hovered
 * @param state current app state
 * @param entityId the entity to check
 */
export const selectIsHoveringEntity = curryAppSelector(
  createSelector(
    [selectHoveredEntityId, (state, entityId?: GUID) => entityId],
    (hoveredEntityId, entityId): boolean =>
      !!entityId && hoveredEntityId === entityId,
  ),
);

/**
 * @returns all point cloud streams for the hovered entity
 * @param state current app state
 */
export const selectPointCloudStreamIdsForHoveredEntity = createSelector(
  [(state: RootState) => state],
  (state: RootState): GUID[] | undefined => {
    if (!state.dataPreparationUi.hoveredEntityId) {
      return;
    }

    return selectPointCloudStreamIdsForEntity(
      state.dataPreparationUi.hoveredEntityId,
    )(state);
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } },
);

/**
 * @param state The current application state
 * @returns Whether the user can edit the registration results.
 */
export function selectIsEditModeEnabled(state: RootState): boolean {
  return state.dataPreparationUi.isEditModeEnabled;
}
