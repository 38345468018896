import { EditElementProperties, EventType } from "@/analytics/analytics-events";
import { ElementIconType } from "@/components/ui/icons/element-icon-type";
import {
  fetchProjectIElements,
  selectIElement,
} from "@faro-lotv/app-component-toolbox";
import { DatePicker, DatePickerVariant, TextField } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { combineDateAndTime } from "@faro-lotv/foundation";
import {
  isIElementGenericDataset,
  isIElementSectionDataSession,
  ISOTimeString,
  MAX_NAME_LENGTH,
} from "@faro-lotv/ielement-types";
import {
  createMutationSetElementCreatedAt,
  createMutationSetElementName,
  Mutation,
} from "@faro-lotv/service-wires";
import { useState } from "react";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";

export const EDIT_ELEMENT_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.editElement,
  label: "Edit",
  icon: ElementIconType.EditIcon,
  handler: async ({
    elementID,
    state,
    dark,
    apiClients,
    dispatch,
    createDialog,
    errorHandlers,
    setConfirmButtonDisabled,
  }) => {
    const iElement = selectIElement(elementID)(state);
    if (!iElement) return;

    let newName: string | undefined;
    let newTimePoint: Date | undefined;

    const allowTimePointEditing =
      isIElementGenericDataset(iElement) ||
      isIElementSectionDataSession(iElement);

    let isNameValid = true;
    let isTimePointValid = true;

    // Create edit confirmation dialog
    await createDialog({
      title: "Edit",
      confirmText: "Edit",
      isConfirmDisabled: true,
      dark,
      content: (
        <EditElementForm
          dark={dark}
          name={iElement.name}
          onNameChanged={(value, isValid) => {
            newName = value;
            isNameValid = isValid;

            setConfirmButtonDisabled?.(!isNameValid || !isTimePointValid);
          }}
          allowTimePointEditing={allowTimePointEditing}
          initialTimePoint={iElement.createdAt}
          onTimePointChanged={(newDate) => {
            newTimePoint = newDate;
            isTimePointValid = !!newDate;

            setConfirmButtonDisabled?.(!isNameValid || !isTimePointValid);
          }}
        />
      ),
      allowToCloseWithoutCancelling: true,
      size: "s",
      showXButton: true,
      onConfirm: async () => {
        const mutations: Mutation[] = [];

        Analytics.track<EditElementProperties>(EventType.editElement, {
          elementType: iElement.type,
          // Need to nullish coalesce to undefined here because null is not allowed
          elementTypeHint: iElement.typeHint ?? undefined,
          hasEditedName: !!newName,
          hasEditedTimePoint: !!newTimePoint,
        });

        if (newName) {
          mutations.push(createMutationSetElementName(elementID, newName));
        }

        if (allowTimePointEditing && !!newTimePoint) {
          // Because users are only able to change the date, we want to keep the time from the old timestamp for now.
          const newCreatedAt = combineDateAndTime(
            newTimePoint,
            new Date(iElement.createdAt),
          );

          mutations.push(
            createMutationSetElementCreatedAt(
              elementID,
              newCreatedAt.toISOString(),
            ),
          );
        }

        try {
          const [resultMutation] =
            await apiClients.projectApiClient.applyMutations(mutations);
          if (resultMutation.status === "failure") {
            const errorMessage = resultMutation.message
              ? `Failed to edit element with error message: ${resultMutation.message}`
              : "Failed to edit element";
            throw new Error(errorMessage);
          }
          // Fetch the changed sub-tree and update the local copy of the project
          await dispatch(
            fetchProjectIElements({
              fetcher: async () =>
                await apiClients.projectApiClient.getAllIElements({
                  ancestorIds: [elementID],
                }),
            }),
          );

          return true;
        } catch (error) {
          errorHandlers.handleErrorWithDialog({
            title: "Unable to edit Element",
            error,
          });
          return false;
        }
      },
    });
  },
};

type EditElementFormProps = {
  /** Label displayed on top of the field */
  name: string;

  /** Display the dark themed version of the dialog */
  dark?: boolean;

  /**
   * Callback executed when the name changed.
   *
   * @param name new value of the name entered by user
   * @param nameIsValid true if this new name is valid, false if invalid
   */
  onNameChanged(name: string, nameIsValid: boolean): void;

  /** Whether to allow editing of the time point */
  allowTimePointEditing: boolean;

  /** The initial time point in the form */
  initialTimePoint: ISOTimeString;

  /** Callback executed when the user changes the time point */
  onTimePointChanged?(newDate: Date | undefined): void;
};

function EditElementForm({
  name,
  dark,
  onNameChanged,
  allowTimePointEditing,
  initialTimePoint,
  onTimePointChanged,
}: EditElementFormProps): JSX.Element {
  const [newName, setNewName] = useState(name);
  const [isFirstFocus, setIsFirstFocus] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [timePoint, setTimePoint] = useState<Date | undefined>(
    () => new Date(initialTimePoint),
  );

  return (
    <>
      <TextField
        dark={dark}
        label="Name"
        text={newName}
        autoFocus
        fullWidth
        onTextChanged={(value) => {
          setNewName(value);
          const errorMessage =
            value.trimStart().length === 0 ? "Name cannot be empty" : undefined;
          onNameChanged(value, !errorMessage);
          setErrorMessage(errorMessage);
        }}
        inputProps={{ maxLength: MAX_NAME_LENGTH }}
        maxCharacterCount={MAX_NAME_LENGTH}
        shouldShowCounter
        error={errorMessage}
        onFocus={(event) => {
          // Select all text when the field is focused for the first time
          if (isFirstFocus) {
            event.target.select();
            setIsFirstFocus(false);
          }
        }}
      />
      {allowTimePointEditing && (
        <DatePicker
          variant={dark ? DatePickerVariant.Dark : DatePickerVariant.Light}
          label="Time point"
          date={timePoint}
          onChange={(newDate) => {
            setTimePoint(newDate);
            onTimePointChanged?.(newDate);
          }}
          error={!timePoint}
        />
      )}
    </>
  );
}
