import { ProjectIntegrationProviderName } from "@/store/integrations/integrations-selectors";
import { assert } from "@faro-lotv/foundation";
import {
  ExternalMarkupIElement,
  IElementType,
} from "@faro-lotv/ielement-types";
import { BcfServicesIntegrationType } from "@faro-lotv/service-wires";

/**
 * Represents the names of different types of external annotations
 */
enum ExternalAnnotationTypeName {
  /** For external annotation of type Issue */
  Issue = "Issue",
  /** For external annotation of type RFI */
  Rfi = "RFI",
  /** For external annotation of type Observation */
  Observation = "Observation",
}

type IntegrationsDataAssociations = {
  /** The "BCF Services" integration type */
  bcfIntegrationType: BcfServicesIntegrationType;
  /** The iElementType corresponding to the "BCF Services" integration type */
  iElementType: ExternalMarkupIElement["type"];
  /** The name of the project integration provider */
  providerName: ProjectIntegrationProviderName;
  /** The name of the annotation type */
  annotationTypeName: ExternalAnnotationTypeName;
};

// The associations between "BCF Services" integration types and the other related data adn types defined elsewhere
const integrationsDataAssociations: IntegrationsDataAssociations[] = [
  {
    bcfIntegrationType: BcfServicesIntegrationType.autodeskAccIssues,
    iElementType: IElementType.markupAccIssue,
    providerName: ProjectIntegrationProviderName.autodeskAcc,
    annotationTypeName: ExternalAnnotationTypeName.Issue,
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.autodeskAccRfis,
    iElementType: IElementType.markupAccRfi,
    providerName: ProjectIntegrationProviderName.autodeskAcc,
    annotationTypeName: ExternalAnnotationTypeName.Rfi,
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.autodeskBim360Issues,
    iElementType: IElementType.markupBim360,
    providerName: ProjectIntegrationProviderName.autodeskBim360,
    annotationTypeName: ExternalAnnotationTypeName.Issue,
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.procoreObservations,
    iElementType: IElementType.markupProcoreObservation,
    providerName: ProjectIntegrationProviderName.procore,
    annotationTypeName: ExternalAnnotationTypeName.Observation,
  },
  {
    bcfIntegrationType: BcfServicesIntegrationType.procoreRfis,
    iElementType: IElementType.markupProcoreRfi,
    providerName: ProjectIntegrationProviderName.procore,
    annotationTypeName: ExternalAnnotationTypeName.Rfi,
  },
];

/**
 * @param providerName The name of the integration provider
 * @returns all "BCF Services" integration types for the given project integration provider
 */
export function getAllBcfServicesIntegrationTypes(
  providerName: ProjectIntegrationProviderName,
): BcfServicesIntegrationType[] {
  return integrationsDataAssociations
    .filter((association) => association.providerName === providerName)
    .map((association) => association.bcfIntegrationType);
}

/**
 * @param bcfIntegrationType  The bcf integration type for which the we need the corresponding iElement type
 * @returns The iElement type corresponding to the given bcf integration type
 * @throws Will throw an error if the specified bcf integration type is not found.
 */
export function getIElementTypeForBcfIntegrationType(
  bcfIntegrationType: BcfServicesIntegrationType,
): ExternalMarkupIElement["type"] {
  const valueFound = integrationsDataAssociations.find(
    (association) => association.bcfIntegrationType === bcfIntegrationType,
  );
  assert(
    valueFound,
    `The bcf service integration type ${bcfIntegrationType} is not found`,
  );
  return valueFound.iElementType;
}

/**
 * @param iElementType The iElement type for which the we need the corresponding BCF Services integration type
 * @returns The BCF Services integration type corresponding to the given iElement type
 * @throws Will throw an error if the specified iElement type is not found.
 */
export function getBcfIntegrationTypeForIElementType(
  iElementType: ExternalMarkupIElement["type"],
): BcfServicesIntegrationType {
  const valueFound = integrationsDataAssociations.find(
    (association) => association.iElementType === iElementType,
  );
  assert(valueFound, `The iElementType ${iElementType} is not found`);
  return valueFound.bcfIntegrationType;
}

/**
 * @param bcfIntegrationType - The BCF integration type for which to find the provider name.
 * @returns The provider name corresponding to the specified BCF integration type.
 * @throws Will throw an error if the specified BCF integration type is not found.
 */
export function getExternalAnnotationProviderName(
  bcfIntegrationType: BcfServicesIntegrationType,
): ProjectIntegrationProviderName {
  const valueFound = integrationsDataAssociations.find(
    (association) => association.bcfIntegrationType === bcfIntegrationType,
  );
  assert(
    valueFound,
    `The bcf service integration type ${bcfIntegrationType} is not found`,
  );
  return valueFound.providerName;
}

/**
 * @param bcfIntegrationType - The BCF integration type for which to find the annotation type name.
 * @returns The annotation type name corresponding to the specified BCF integration type.
 * @throws Will throw an error if the specified BCF integration type is not found.
 */
export function getExternalAnnotationTypeName(
  bcfIntegrationType: BcfServicesIntegrationType,
): ExternalAnnotationTypeName {
  const valueFound = integrationsDataAssociations.find(
    (association) => association.bcfIntegrationType === bcfIntegrationType,
  );
  assert(
    valueFound,
    `The bcf service integration type ${bcfIntegrationType} is not found`,
  );
  return valueFound.annotationTypeName;
}
