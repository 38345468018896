import { useFileUploaderWithPromise } from "@/components/common/file-upload-context/use-file-uploader";
import { UploadElementType } from "@/components/common/point-cloud-file-upload-context/use-upload-element";
import { useCurrentArea } from "@/modes/mode-data-context";
import { useAppSelector } from "@/store/store-hooks";
import { FileDetails } from "@faro-lotv/flat-ui";
import { GUID, assert } from "@faro-lotv/foundation";
import { selectProjectId } from "@faro-lotv/project-source";
import { useApiClientContext } from "@faro-lotv/service-wires";
import { useCallback, useState } from "react";
import { NewAttachment } from "./annotation-props";

/** For new files, the urlOrFile prop is a File */
type NewFile = Omit<FileDetails, "urlOrFile"> & { urlOrFile: File };

/**
 * @returns True if the attachments is a new attachment uploaded by the user
 * @param attachment The attachment to check
 */
function isNewFile(attachment: FileDetails): attachment is NewFile {
  return attachment.urlOrFile instanceof File;
}

type AnnotationUploadAttachments = {
  /** The callback to upload the attachments to the backend */
  uploadAttachments(attachments: FileDetails[]): Promise<NewAttachment[]>;
  /** The progress tracking the upload of the files */
  progress: number | undefined;
};

/**
 * @returns A callback to upload the attachments to the backend, while tracking the progress
 */
export function useAnnotationUploadAttachments(): AnnotationUploadAttachments {
  const { coreApiClient } = useApiClientContext();
  const { area } = useCurrentArea();
  const uploadFile = useFileUploaderWithPromise();
  const projectId = useAppSelector(selectProjectId);
  assert(projectId, "Invalid project ID");

  const [progress, setProgress] = useState<number>();

  const uploadAttachments = useCallback(
    async (attachments: FileDetails[]) => {
      const progressTracker: Record<GUID, number> = {};
      const newFiles = attachments.filter(isNewFile);

      const fileUploads = await Promise.all(
        newFiles.map((attachment) =>
          Promise.all([
            uploadFile({
              file: attachment.urlOrFile,
              uploadElementType: UploadElementType.none,
              projectId,
              coreApiClient,
              areaId: area?.id,
              silent: true,
              onUploadUpdated: (id, progress) => {
                progressTracker[id] = progress;
                const percentages = Object.values(progressTracker);
                const cumulativeProgress = percentages.reduce((prev, next) => {
                  return prev + next / percentages.length;
                }, 0);
                setProgress(cumulativeProgress);
              },
            }),
            attachment.thumbnailUrl instanceof File
              ? uploadFile({
                  file: attachment.thumbnailUrl,
                  uploadElementType: UploadElementType.none,
                  projectId,
                  coreApiClient,
                  areaId: area?.id,
                  silent: true,
                })
              : undefined,
          ]),
        ),
      ).finally(() => setProgress(undefined));

      return fileUploads.map(([file, thumbnail], index) => ({
        ...file,
        ...newFiles[index],
        ...(thumbnail && {
          thumbnailUrl: thumbnail.downloadUrl,
        }),
      }));
    },
    [area?.id, coreApiClient, projectId, uploadFile],
  );

  return {
    uploadAttachments,
    progress,
  };
}
