import { useFileUploadContext } from "@/components/common/file-upload-context/file-uploads-context";
import { useElementFileUploadContext } from "@/components/common/point-cloud-file-upload-context/element-file-upload-context";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectBackgroundTasks } from "@/store/background-tasks/background-tasks-selector";
import { changeMode } from "@/store/mode-slice";
import { selectLayerAreaElementId } from "@/store/modes/alignment-wizard-mode-selectors";
import {
  resetLayerAreaElementId,
  setWizardElementToAlignId,
  setWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { store } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { FileUploadTask, isFileUploadTask } from "@/utils/background-tasks";
import {
  CombinedUploadProgress,
  combinedUploadProgress,
} from "@/utils/combined-upload-progress";
import { useToast } from "@faro-lotv/flat-ui";
import { GUID, removeExtension } from "@faro-lotv/foundation";
import {
  IElementGenericImgSheet,
  isIElementGenericImgSheet,
  isValidPose,
} from "@faro-lotv/ielement-types";
import {
  selectChildrenDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { isEqual } from "es-toolkit";
import { useCallback, useEffect, useState } from "react";
import {
  isSupportedImgSheetFileExtension,
  useCreateArea,
  verifyInputName,
} from "./create-area-utils";
import { RelativeCrop } from "./image-crop";
import { useDefaultGrid } from "./use-default-grid";
import { useParseUserImage } from "./use-parse-user-image";

type CreateAreaLogic = {
  /** File currently selected */
  file?: File;

  /** Name of the Area and ImgSheet to create */
  inputName: string;

  /** The validation error to show for the input name */
  inputNameError?: string;

  /** Progress of the upload tasks */
  uploadProgress?: CombinedUploadProgress;

  /** True if the default grid is available */
  hasDefaultGrid: boolean;

  /** True, if the image has been resized */
  hasImgBeenResized: boolean;

  /** Set the name of the new area */
  setInputName(name: string): void;

  /** Function that creates a new Area Section, uploads the file and creates a new ImgSheet */
  createSheet(): Promise<void>;

  /** Function that resets the input file and cancels the upload if the user selects so in the dialog */
  cancelUpload(): Promise<void>;

  /** Function to set the current file */
  setFile(file?: File): void;

  /** Function to set the default grid as the current floor image */
  setDefaultGrid(): void;

  /** total number of pages in PDF document; zero if file is not *.pdf */
  numberOfPdfPages?: number;

  /**
   * callback used to update selection of the page from multi-pages PDF file
   *
   * @param pageNumber selected page number (numerated from 1 to numberOfPdfPages)
   */
  onUpdateSelectedPdfPage(pageNumber: number): void;

  /** selected page number if file is PDF (numerated from 1 to numberOfPdfPages). Ignored if file is not PDF */
  selectedPdfPage: number;

  /** ratio width/height of original image - used to scale rotated image in preview  */
  widthToHeightRatio: number;

  /** rotation angle in degrees  */
  rotation: number;

  /**
   * method to set rotation angle
   *
   * @param rotation rotation angle in degrees
   */
  setRotation(rotation: number): void;

  /**
   * method to set crop parameters
   *
   * @param rotation crop parameters (in % of original image)
   */
  setCrop(crop?: RelativeCrop): void;
};

/**
 * @returns the internal state and mutations for the create area logic
 */
export function useCreateAreaLogic(): CreateAreaLogic {
  const dispatch = useAppDispatch();

  // Project editing
  const createArea = useCreateArea();

  const existingAreaId = useAppSelector(selectLayerAreaElementId);
  const existingArea = useAppSelector(selectIElement(existingAreaId));

  const [existingLayers, setExistingLayers] = useState<
    IElementGenericImgSheet[]
  >([]);

  useEffect(() => {
    setExistingLayers(
      existingArea
        ? selectChildrenDepthFirst(
            existingArea,
            isIElementGenericImgSheet,
          )(store.getState())
        : [],
    );
  }, [existingArea]);

  // File upload and task tracking
  const { uploadManager } = useFileUploadContext();
  const { uploadAreaImageFile } = useElementFileUploadContext();
  const [sectionAreaId, setSectionAreaId] = useState<GUID>();
  const [uploadProgress, setUploadProgress] =
    useState<CombinedUploadProgress>();

  // Ui Helpers
  const { handleErrorWithDialog } = useErrorHandlers();
  const { openToast } = useToast();

  // Floor image state
  const defaultGrid = useDefaultGrid();
  const {
    parsedImage,
    hasImgBeenResized,
    parseImage,
    numberOfPdfPages,
    widthToHeightRatio,
  } = useParseUserImage();

  // preserve selection of the file to be used when user change only page (for PDF)
  const [selectedFile, setSelectedFile] = useState<File>();

  // Define the state variables for the current PDF page selection in dropdown
  const [selectedPdfPage, setSelectedPdfPage] = useState(1);

  const onUpdateSelectedPdfPage = useCallback(
    (pageNumber: number) => {
      setSelectedPdfPage(pageNumber);
      parseImage(selectedFile, pageNumber);
    },
    [selectedFile, parseImage],
  );

  // Area name state
  const [inputName, setInputNameInternal] = useState("");
  const [inputNameError, setInputNameError] = useState<string>();

  // rotation of the image
  const [rotation, setRotation] = useState(0);

  // crop of the image
  const [crop, setCrop] = useState<RelativeCrop>();

  // when selected image changed reset rotation
  useEffect(() => {
    setRotation(0);
  }, [parsedImage]);

  // Validate area name every time it changes
  const setInputName = useCallback((name: string) => {
    setInputNameInternal(name);
    setInputNameError(verifyInputName(name));
  }, []);

  // Track the task for the file upload of the Sheet Image
  // This will take the first ongoing task available for the element
  // NOTE: this will have to be updated when it will be allowed to add a new Sheet Image to a non empty project
  const tasks = useAppSelector((state) => {
    return selectBackgroundTasks(
      (task): task is FileUploadTask =>
        task.iElementId === sectionAreaId && isFileUploadTask(task),
    )(state);
  }, isEqual);
  const reportedProgress = combinedUploadProgress(tasks);
  useEffect(() => {
    setUploadProgress(reportedProgress);
  }, [reportedProgress]);

  // Reset the workflow to the initial state (no image, no area name)
  const resetToInitialState = useCallback(() => {
    parseImage(undefined);
    setInputName("");
    setInputNameError(undefined);
  }, [parseImage, setInputName]);

  const completeSheetUpload = useCallback(() => {
    if (existingArea) {
      dispatch(resetLayerAreaElementId());

      const sheets = selectChildrenDepthFirst(
        existingArea,
        isIElementGenericImgSheet,
      )(store.getState());

      // Find newly added layer as item in sheets array that was not in existingLayers based on `id`
      const newLayer = sheets.filter(
        (item1) => !existingLayers.find((item2) => item1.id === item2.id),
      );

      const elementToAlignId = newLayer.length ? newLayer[0].id : undefined;

      if (elementToAlignId) {
        const candidateReferenceLayer = sheets.find(
          (sh) => sh.id !== elementToAlignId && isValidPose(sh.pose),
        );

        if (candidateReferenceLayer) {
          dispatch(setWizardReferenceElementId(candidateReferenceLayer.id));
        }
        dispatch(setWizardElementToAlignId(elementToAlignId));
        dispatch(changeMode("alignWizard"));
        return;
      }
    }
    dispatch(changeMode("sheet"));
  }, [dispatch, existingArea, existingLayers]);

  // Create a sheet with the current workflow state
  const createSheet = useCallback(async () => {
    if (!parsedImage) {
      openToast({
        title: "Sheet upload failed",
        message: "Please make sure an image is selected",
        variant: "error",
      });
      return;
    }

    const inputNameError = verifyInputName(inputName);
    if (inputNameError) {
      setInputNameError(inputNameError);
      return;
    }

    // Show immediately the progress at 0 while we wait for the upload to start
    setUploadProgress({ progress: 0 });

    // Create a Section Area if there is none present in the project
    // The function will return the id of the new area of the already existing area
    const sectionAreaId = existingAreaId ?? (await createArea(inputName));

    if (sectionAreaId) {
      // Set the active element to the newly created Section Area
      dispatch(setActiveElement(sectionAreaId));

      setSectionAreaId(sectionAreaId);

      // Start the upload of the file to the backend
      // After the upload, a mutation to add the ImgSheet will be sent
      // And finally when the uploadManager emits the event onCompleted the passed function will be executed
      uploadAreaImageFile(
        inputName,
        sectionAreaId,
        rotation,
        crop,
        parsedImage,
        completeSheetUpload,
      );
    }
  }, [
    parsedImage,
    inputName,
    existingAreaId,
    createArea,
    openToast,
    dispatch,
    uploadAreaImageFile,
    rotation,
    crop,
    completeSheetUpload,
  ]);

  // Cancel the current file upload and stop the workflow
  const cancelUpload = useCallback(() => {
    for (const { id } of tasks) {
      uploadManager.cancelFileUpload(id);
    }
    resetToInitialState();
    return Promise.resolve();
  }, [resetToInitialState, tasks, uploadManager]);

  // Set a custom floor image file
  const setFile = useCallback(
    (file: File) => {
      setSelectedFile(file);
      setSelectedPdfPage(1);

      try {
        const fileName = file.name;
        if (isSupportedImgSheetFileExtension(fileName)) {
          parseImage(file);
          if (
            !inputName.length ||
            inputName === removeExtension(parsedImage?.name)
          ) {
            setInputName(removeExtension(fileName));
          }
        } else {
          openToast({
            title: "Failed to import file",
            message: "The file extension is not supported.",
            variant: "error",
          });
        }
      } catch (error) {
        handleErrorWithDialog({ title: "Image sheet upload", error });
      }
    },
    [
      handleErrorWithDialog,
      inputName,
      openToast,
      parseImage,
      parsedImage?.name,
      setInputName,
    ],
  );

  // Load the default grid as the floor image
  const setDefaultGrid = useCallback(() => {
    if (!defaultGrid) return;
    setFile(defaultGrid);
    setInputName(inputName);
    setInputNameError(undefined);
  }, [defaultGrid, inputName, setFile, setInputName]);

  return {
    file: parsedImage,
    inputName,
    inputNameError,
    uploadProgress,
    hasDefaultGrid: !!defaultGrid,
    hasImgBeenResized,
    setFile,
    setInputName,
    cancelUpload,
    createSheet,
    setDefaultGrid,
    widthToHeightRatio,
    rotation,
    setRotation,
    setCrop,
    numberOfPdfPages,
    onUpdateSelectedPdfPage,
    selectedPdfPage,
  };
}
