import { WebGLRenderer, WebGLRenderTarget } from "three";
import { NormalizeOpacityMaterial } from "../Materials/NormalizeOpacityMaterial";
import { FullScreenPass } from "./FullScreenPass";

const MAX_ALPHA = 255;

/**
 * A ThreeJS effect pass to apply opacity normalization, i.e. to rescale it to a range of [0, 1].
 */
export class NormalizeOpacityPass extends FullScreenPass<NormalizeOpacityMaterial> {
	/** Buffer to store the pixels of the read buffer, for determining the max alpha. */
	protected pixelBuffer: Uint8Array = new Uint8Array(0);

	/**
	 * Default constructor
	 */
	constructor() {
		super(new NormalizeOpacityMaterial());
	}

	/**
	 * Renders the Fx Anti-Aliasing
	 *
	 * @param renderer renderer used to render the effect
	 * @param writeBuffer Buffer to write by renderer
	 * @param readBuffer Buffer to read the textures by renderer
	 */
	render(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget): void {
		const bufferLength = readBuffer.width * readBuffer.height * 4;
		if (this.pixelBuffer.length < bufferLength) {
			this.pixelBuffer = new Uint8Array(bufferLength);
		}
		renderer.readRenderTargetPixels(readBuffer, 0, 0, readBuffer.width, readBuffer.height, this.pixelBuffer);

		// For now, determine the max alpha value by reading the texture directly
		let maxAlpha = 0.0;

		for (let pixel = 0; pixel < readBuffer.width * readBuffer.height; pixel++) {
			// 4 channels (RGBA), alpha is the last one
			const alphaIdx = pixel * 4 + 3;
			const alphaUInt8 = this.pixelBuffer.at(alphaIdx);
			if (alphaUInt8) {
				const alpha = alphaUInt8 / MAX_ALPHA;
				maxAlpha = Math.max(maxAlpha, alpha);
			}
		}

		// Finally, apply the opacity normalization, using the aggregated maximum opacity value
		this.material.uniforms.uColorTex.value = readBuffer.texture;
		this.material.uniforms.uMaxAlpha.value = maxAlpha;
		this.material.uniformsNeedUpdate = true;

		renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer);
		this.fsQuad.render(renderer);
	}
}
