import { computeDeepLink } from "@/components/common/deep-link/deep-link-utils";
import { useViewRuntimeContext } from "@/components/common/view-runtime-context";
import { createAnnotationFields } from "@/components/ui/annotations/annotation-fields";
import {
  AnnotationCreationData,
  createPostTopic,
  ExternalAnnotationData,
  isExternalAnnotationData,
  SphereXGAnnotationData,
} from "@/components/ui/annotations/annotation-props";
import { createAttachments } from "@/components/ui/annotations/attachment-mutations";
import {
  getExternalAnnotationProviderName,
  getIElementTypeForBcfIntegrationType,
} from "@/components/ui/annotations/external-annotation-utils";
import { useCurrentArea } from "@/modes/mode-data-context";
import { selectProjectIdForIntegrationType } from "@/store/integrations/integrations-selectors";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { assert, generateGUID, GUID } from "@faro-lotv/foundation";
import {
  fetchProjectIElements,
  selectAdvancedMarkupTemplateIds,
  selectProjectId,
} from "@faro-lotv/project-source";
import {
  createMutationAddExternalMarkup,
  createMutationAddLabel,
  createMutationAddMarkup,
  createMutationAddMarkupPolygon,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback } from "react";
import {
  PointAnnotationData,
  selectAddPolygonMutationData,
} from "../annotation-mutation-utils";

/**
 * Defines the type of the function that will be used for the creation of
 * Sphere XG single point annotations or for external single point annotations
 */
type CreateSinglePointAnnotation = (
  annotation: PointAnnotationData,
  details: AnnotationCreationData,
) => Promise<GUID>;

/**
 * @returns a function to use for the creation of Sphere XG single point annotation or
 * for external single point annotation. The returned function will throw an error if
 * the creation of the annotation fails.
 */
export function useCreateSinglePointAnnotation(): CreateSinglePointAnnotation {
  const createSphereSinglePointAnnotation =
    useCreateSphereSinglePointAnnotation();
  const createExternalSinglePointAnnotation =
    useCreateExternalMarkupSinglePointAnnotation();

  return useCallback<CreateSinglePointAnnotation>(
    (annotation, details) => {
      if (isExternalAnnotationData(details)) {
        return createExternalSinglePointAnnotation(annotation, details);
      }

      return createSphereSinglePointAnnotation(annotation, details);
    },
    [createExternalSinglePointAnnotation, createSphereSinglePointAnnotation],
  );
}

/**
 * Defines the type of the function that should be used for the creation of
 * Sphere XG single point annotations
 */
type CreateSphereSinglePointAnnotation = (
  annotation: PointAnnotationData,
  details: SphereXGAnnotationData,
) => Promise<GUID>;

/**
 * @returns a function to use for the creation of Sphere XG single point annotation.
 * The returned function will throw an error if the creation of the annotation fails.
 */
function useCreateSphereSinglePointAnnotation(): CreateSphereSinglePointAnnotation {
  const projectId = useAppSelector(selectProjectId);
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const { projectApiClient } = useApiClientContext();
  const appStore = useAppStore();
  const { area } = useCurrentArea();

  return useCallback<CreateSphereSinglePointAnnotation>(
    (annotation, details) => {
      const {
        title,
        assignee,
        description,
        dueDate,
        status,
        newAttachments,
        tags,
      } = details;
      async function createAnnotation(): Promise<GUID> {
        assert(currentUser, "Expected a logged in user");
        assert(projectId, "Expected a project ID");

        const appState = appStore.getState();

        const markupPolygonId = generateGUID();

        assert(area, "Expected an area to be defined");

        const mutationData = selectAddPolygonMutationData(
          annotation,
          area,
        )(appState);
        assert(
          mutationData,
          "Unable to compute the data to store for the new annotation",
        );

        const addMarkupPolygonMutation = createMutationAddMarkupPolygon({
          ...mutationData,
          newElementId: markupPolygonId,
          name: title,
          typeHint: annotation.type,
        });

        const markupId = generateGUID();
        const templateIds = selectAdvancedMarkupTemplateIds(appState);
        assert(
          templateIds,
          "Expected project to have an advanced markup template",
        );

        const markupFields = createAnnotationFields({
          assignee,
          status,
          dueDate,
          ...templateIds,
          currentUserId: currentUser.id,
          markupId,
          rootId: mutationData.rootId,
        });

        const addMarkupMutation = createMutationAddMarkup({
          id: markupId,
          templateId: templateIds.templateId,
          rootId: mutationData.rootId,
          name: title,
          description: description ?? "",
          annotationId: markupPolygonId,
          markupFields,
        });

        const attachmentsMutations = createAttachments(
          mutationData.rootId,
          markupId,
          newAttachments,
        );

        const tagsMutations =
          tags?.map((tag) => createMutationAddLabel(markupId, tag.id)) ?? [];

        await projectApiClient.applyMutations([
          addMarkupPolygonMutation,
          addMarkupMutation,
          ...attachmentsMutations,
          ...tagsMutations,
        ]);

        await dispatch(
          fetchProjectIElements({
            fetcher: () =>
              projectApiClient.getAllIElements({
                ancestorIds: [mutationData.sectionId],
              }),
          }),
        );

        return markupId;
      }

      return createAnnotation();
    },
    [currentUser, projectId, appStore, area, projectApiClient, dispatch],
  );
}

/**
 * Defines the type of the function that should be used for the creation of
 * external single point annotations
 */
type CreateExternalSinglePointAnnotation = (
  annotation: PointAnnotationData,
  details: ExternalAnnotationData,
) => Promise<GUID>;

/**
 * @returns a function to use for the creation of external single point annotation.
 * The returned function will throw an error if the creation of the annotation fails.
 */
function useCreateExternalMarkupSinglePointAnnotation(): CreateExternalSinglePointAnnotation {
  const dispatch = useAppDispatch();
  const { bcfServicesApiClient, projectApiClient } = useApiClientContext();
  const appStore = useAppStore();
  const { area } = useCurrentArea();
  const viewContext = useViewRuntimeContext();

  return useCallback<CreateExternalSinglePointAnnotation>(
    (annotation, details) => {
      async function createAnnotation(): Promise<GUID> {
        const appState = appStore.getState();
        const markupPolygonId = generateGUID();

        assert(area, "Expected an area to be defined");

        const mutationData = selectAddPolygonMutationData(
          annotation,
          area,
        )(appState);
        assert(
          mutationData,
          "Unable to compute the data to store for the new annotation",
        );

        const externalMarkupId = generateGUID();
        const deepLinkUrl = computeDeepLink(
          appState,
          viewContext,
          externalMarkupId,
        ).toString();
        const { externalAnnotationType } = details;

        const postTopic = createPostTopic({
          data: details,
          id: externalMarkupId,
          deepLinkUrl,
          providerName: getExternalAnnotationProviderName(
            externalAnnotationType,
          ),
        });

        const integrationProjectId = selectProjectIdForIntegrationType(
          externalAnnotationType,
        )(appState);
        assert(
          integrationProjectId,
          "integration project id should be defined",
        );
        const getTopic = await bcfServicesApiClient.createTopic(
          externalAnnotationType,
          integrationProjectId,
          postTopic,
        );

        const addExternalMarkupMutation = createMutationAddExternalMarkup({
          id: externalMarkupId,
          type: getIElementTypeForBcfIntegrationType(externalAnnotationType),
          externalIssueId: getTopic.guid,
          parentId: markupPolygonId,
          rootId: mutationData.rootId,
        });

        const addMarkupPolygonMutation = createMutationAddMarkupPolygon({
          ...mutationData,
          newElementId: markupPolygonId,
          name: getTopic.guid,
          typeHint: annotation.type,
        });

        await projectApiClient.applyMutations([
          addMarkupPolygonMutation,
          addExternalMarkupMutation,
        ]);

        await dispatch(
          fetchProjectIElements({
            fetcher: () =>
              projectApiClient.getAllIElements({
                ancestorIds: [mutationData.sectionId],
              }),
          }),
        );

        return externalMarkupId;
      }
      return createAnnotation();
    },
    [
      appStore,
      area,
      bcfServicesApiClient,
      dispatch,
      projectApiClient,
      viewContext,
    ],
  );
}
