import { ShaderMaterial, Texture } from "three";
import frag from "../Shaders/NormalizeOpacity.frag";
import vert from "../Shaders/TexturedQuad.vert";
import { makeUniform } from "./Uniforms";

/**
 * Normalize the opacity of the scene, e.g. rescale it to a range of [0, 1]
 */
export class NormalizeOpacityMaterial extends ShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		uMaxAlpha: makeUniform(0.0),
		uDebugAlpha: makeUniform(false),
		uColorTex: makeUniform<Texture | null>(null),
	};

	/**
	 * Default constructor
	 *
	 * @param parameters An object with one or more properties defining the material's appearance.
	 */
	constructor() {
		super();
	}
}
