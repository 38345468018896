import { ButtonBaseActions } from "@mui/material";
import { omit } from "es-toolkit";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaroDialog, FaroDialogProps } from "./faro-dialog";

export type CreateDialogProps = Omit<
  FaroDialogProps,
  "open" | "onConfirm" | "onCancel" | "onClose"
> & {
  /** If specified and false, the dialog will not contain the Cancel button, if not specified or true cancel button is visible */
  showCancelButton?: boolean;
  /** The content of the dialog */
  content: JSX.Element | string;
  /** Function called before confirming the dialog */
  onConfirm?: (() => Promise<boolean>) | (() => boolean);
  /** Function called before canceling the dialog */
  onCancel?(): void;
  /**
   * Indicates if true the dialog can be closed without pressing on the cancel button
   *
   * @default true when showCancelButton is false or undefined, false when showCancelButton is true
   * It means that by default the user can't close the dialog without pressing on the cancel button when that one is shown.
   * When set to true while showCancelButton is also set to true, the user will be able to close
   * the dialog by pressing the X on the top right or by clicking outside the dialog or pressing the esc key
   */
  allowToCloseWithoutCancelling?: boolean;
};

type DialogData = {
  /**
   * @returns a promise with a boolean value, which is true if the dialog has been confirmed and false otherwise
   * @param options - object which sets the possible customization for the dialog
   * @example
   *```ts
   * const { createDialog } = useDialog();
   *
   * const handleDialog = useCallback(async () => {
   *    // Creates a modal dialog and waits for the user to perform an action
   *    const hasConfirmed = await createDialog({
   *      title: "Demo",
   *      content: "Demo Content",
   *    });
   *    if (hasConfirmed) {
   *      // User has confirmed the dialog, your logic goes here
   *    } else {
   *      // User has cancelled / closed the dialog, your logic goes here
   *    }
   * }, [createDialog]);
   * ```
   */
  createDialog(options: CreateDialogProps): Promise<boolean>;

  /**
   * Function to set the state of the confirm button. The button is enabled by default
   *
   * @param isDisabled - true if the button should be disabled, false otherwise
   */
  setConfirmButtonDisabled(isDisabled: boolean): void;
};

/** A dialog context which provides the components with the function to create a dialog */
export const DialogContext = createContext<DialogData | undefined>(undefined);

/**
 *  @returns A dialog provider which allows the components to create a dialog from anywhere
 */
export function DialogProvider({
  children,
}: PropsWithChildren<unknown>): JSX.Element {
  const confirmPromiseRef = useRef<{
    resolve(value: boolean): void;
  }>();

  const [dialogOptions, setDialogOptions] = useState<CreateDialogProps | null>(
    null,
  );
  const [isProcessing, setIsProcessing] = useState(false);

  // Update the isConfirmDisabled props in the active dialogOptions
  const setConfirmButtonDisabled = useCallback((isConfirmDisabled: boolean) => {
    setDialogOptions(
      (prevOptions) =>
        prevOptions && {
          ...prevOptions,
          isConfirmDisabled,
        },
    );
  }, []);

  /**
   * @returns a promise with a boolean value, which is true if the dialog has been confirmed and false otherwise
   * @param options - object which sets the possible customization for the dialog
   */
  const createDialog = useCallback(
    (options: CreateDialogProps): Promise<boolean> => {
      setDialogOptions(options);
      return new Promise((resolve) => {
        confirmPromiseRef.current = { resolve };
      });
    },
    [],
  );

  const handleCancel = useCallback(() => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current.resolve(false);
    }
    dialogOptions?.onCancel?.();
    setDialogOptions(null);
  }, [dialogOptions]);

  const cancelButtonActions = useRef<ButtonBaseActions>(null);
  const handleClose = useCallback(() => {
    if (
      !dialogOptions?.allowToCloseWithoutCancelling &&
      !!cancelButtonActions.current
    ) {
      cancelButtonActions.current.focusVisible();
    } else {
      handleCancel();
    }
  }, [dialogOptions, handleCancel]);

  const handleConfirm = useCallback(async () => {
    let success = true;
    if (dialogOptions?.onConfirm) {
      setIsProcessing(true);
      try {
        success = await dialogOptions.onConfirm();
      } catch {
        success = false;
      }
      setIsProcessing(false);
    }
    if (!success) return;

    if (confirmPromiseRef.current) {
      confirmPromiseRef.current.resolve(true);
    }
    setDialogOptions(null);
  }, [dialogOptions]);

  const showCancelButton = dialogOptions?.showCancelButton ?? true;

  const value = useMemo<DialogData>(
    () => ({
      createDialog,
      setConfirmButtonDisabled,
    }),
    [createDialog, setConfirmButtonDisabled],
  );

  return (
    <DialogContext.Provider value={value}>
      {dialogOptions && (
        <FaroDialog
          open={!!dialogOptions}
          onConfirm={handleConfirm}
          onCancel={showCancelButton ? handleCancel : undefined}
          onClose={handleClose}
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
          isConfirmDisabled={dialogOptions?.isConfirmDisabled}
          showSpinner={isProcessing}
          disabled={isProcessing}
          cancelButtonActions={cancelButtonActions}
          {...omit(dialogOptions, ["content", "onConfirm", "onCancel"])}
        >
          {dialogOptions.content}
        </FaroDialog>
      )}
      {children}
    </DialogContext.Provider>
  );
}

/**
 * @returns Hook that returns the utility function to create a dialog
 */
export function useDialog(): DialogData {
  const ctx = useContext(DialogContext);
  if (!ctx) {
    throw Error("useDialog called outside the DialogContext");
  }
  return ctx;
}
