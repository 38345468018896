import {
  PointCloudStreamIcon,
  PointCloudToggleMenu,
  selectViewablePointCloudStreamsInSameSession,
} from "@/components/common/point-cloud-toggle";
import { useCurrentArea } from "@/modes/mode-data-context";
import { selectPanoColorVariants } from "@/modes/mode-selectors";
import { TypeToggleButtonsLayout } from "@/modes/walk-mode/walk-overlay-responsive-layout";
import { useCached3DObjectIfReady } from "@/object-cache";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { useAppSelector } from "@/store/store-hooks";
import { selectCanReadCAD } from "@/store/subscriptions/subscriptions-selectors";
import { SceneFilter } from "@/types/scene-filter";
import { useLocalStorage } from "@faro-lotv/app-component-toolbox";
import {
  ArrowDownIcon,
  CadSmallIcon,
  FaroPopover,
  FaroPopoverProps,
  FaroTooltip,
  OverlayDataIcon,
  PointCloudIcon,
  neutral,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementImg360,
  isIElementGenericPointCloudStream,
} from "@faro-lotv/ielement-types";
import {
  Box,
  CircularProgress,
  Link,
  ToggleButtonGroup,
  keyframes,
} from "@mui/material";
import { cyan } from "@mui/material/colors";
import { isEqual } from "es-toolkit";
import { useCallback, useMemo, useRef, useState } from "react";
import { TOGGLE_ICON_SX, TypeToggleButton } from "../ui/type-toggle-button";
import { useCadModelLoadingProgress } from "./cad-model-loading-progress";
import { PanoFilterSelector } from "./pano-filter-selector";

/** The input properties for the type toggle group */
type SceneFilterToggleProps = {
  /** The current active type */
  value: SceneFilter;

  /** A callback executed when one of the button is clicked */
  onValueChanged(value: SceneFilter): void;

  /** Call back for when the active element is changed */
  onActiveElementChanged(id: GUID): void;

  /** Function called when the pano type selected in the menu changed */
  onPanoTypeChanged?(showIntensity: boolean, siblingPano: IElementImg360): void;

  /** Selector for getting the currently active element in this View */
  currentMainElement?: IElement;

  /** Show or hide the button related to panorama images */
  panoVisible: boolean;

  /** Enable or disable the button related to point clouds */
  pointCloudEnabled: boolean;

  /** Enable or disable the button related to cads */
  cadEnabled: boolean;

  /** Enable or disable the button related to point cloud and cad overlay */
  overlayEnabled: boolean;

  /** The layout assigned for responsiveness */
  layout: TypeToggleButtonsLayout;

  /** Text to display in the tooltip when the overlay button is disabled */
  overlayDisabledText?: string;
};

/**
 * @returns renderer for the buttons used to toggle the active type for the currently displayed data
 */
export function SceneFilterToggle({
  value,
  onValueChanged,
  onActiveElementChanged,
  onPanoTypeChanged,
  currentMainElement,
  panoVisible,
  pointCloudEnabled,
  cadEnabled,
  overlayEnabled,
  overlayDisabledText,
  layout,
}: SceneFilterToggleProps): JSX.Element {
  const hasCadSupport = useAppSelector(selectCanReadCAD);

  const activeCad = useAppSelector(selectActiveCadModel);
  const cadModelObject = useCached3DObjectIfReady(activeCad);

  // When hovering the "Overlay" button, change the background color
  // for the PointCloud and CAD buttons
  const [overlayHovered, setOverlayHovered] = useState(false);
  const backgroundOnOverlayHover = overlayHovered
    ? {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }
    : {};

  const area = useCurrentArea();
  const pointCloudButton = useRef<HTMLButtonElement>(null);
  const isMultiCloud =
    useAppSelector(
      selectViewablePointCloudStreamsInSameSession(currentMainElement, area),
      isEqual,
    ).length >= 2;
  const [isPointCloudMenuOpen, setIsPointCloudMenuOpen] = useState(false);

  const { isPanoWithIntensity, differentTypeSiblingPano: siblingPano } =
    useAppSelector(selectPanoColorVariants(currentMainElement), isEqual);

  const { isCadModelReady, tooltipMessage, cadLoadingProgress } =
    useCadModelLoadingProgress({ value, cadEnabled });

  // Width in % of the transparent div overlaying the Cad Model button to show loading progress
  // Do not display the progress when the CAD is not displayed.
  const progressWidth = useMemo(() => {
    let perCent = 0;
    if (value === SceneFilter.Cad || value === SceneFilter.Overlay) {
      // only display progress when CAD is displayed
      perCent =
        cadLoadingProgress === undefined
          ? 0
          : 100 - Math.round(cadLoadingProgress);
    }
    return `${perCent}%`;
  }, [cadLoadingProgress, value]);

  return (
    <>
      <ToggleButtonGroup value={value}>
        {panoVisible && (
          <PanoFilterSelector
            filter={value}
            buttonLayout={layout}
            isPanoWithIntensity={isPanoWithIntensity}
            siblingPano={siblingPano}
            onPanoTypeChanged={onPanoTypeChanged}
            onClick={() => onValueChanged(SceneFilter.Pano)}
          />
        )}
        <TypeToggleButton
          ref={pointCloudButton}
          value={SceneFilter.PointCloud}
          aria-label="point cloud"
          disabled={!pointCloudEnabled}
          selected={value === SceneFilter.PointCloud}
          sx={{
            position: "relative",
            gap: 1,

            ...(hasCadSupport && {
              "&.MuiButtonBase-root.MuiToggleButton-root": {
                pr: "26px",
                mr: "-19px",
                ml: 0,
                ...backgroundOnOverlayHover,
              },
              maskImage:
                "radial-gradient(36px 100% at right, transparent, transparent 50%, black 50%, black 100%);",
              maskClip: "no-clip",
            }),
          }}
          onClick={() => {
            if (isMultiCloud) {
              setIsPointCloudMenuOpen(true);
            } else {
              onValueChanged(SceneFilter.PointCloud);
            }
          }}
        >
          <ActivePointCloudIcon currentMainElement={currentMainElement} />
          {layout === TypeToggleButtonsLayout.Large && "Point Cloud"}
          {isMultiCloud && <ArrowDownIcon />}
        </TypeToggleButton>

        {hasCadSupport && (
          <FaroTooltip
            title={overlayDisabledText ?? "Overlay point cloud and 3D model"}
          >
            <TypeToggleButton
              value={SceneFilter.Overlay}
              aria-label="3d model"
              disabled={!overlayEnabled || !isCadModelReady}
              selected={value === SceneFilter.Overlay}
              sx={{
                zIndex: 2,
                width: "36px",
                "&.MuiButtonBase-root.MuiToggleButton-root": {
                  borderRadius: "100%",
                  border: ({ palette }) => `solid 1px ${palette.gray50}6F`,
                  m: 0,
                  boxShadow: "none",
                },
                backdropFilter: "brightness(40%)",
              }}
              onClick={() => onValueChanged(SceneFilter.Overlay)}
              onPointerEnter={() => {
                setOverlayHovered(true);
              }}
              onPointerLeave={() => {
                setOverlayHovered(false);
              }}
            >
              <OverlayDataIcon />
            </TypeToggleButton>
          </FaroTooltip>
        )}
        {hasCadSupport && (
          <FaroTooltip title={tooltipMessage}>
            <TypeToggleButton
              value={SceneFilter.Cad}
              disabled={!cadEnabled || !isCadModelReady}
              selected={value === SceneFilter.Cad}
              sx={{
                gap: 1,
                "&.MuiButtonBase-root.MuiToggleButton-root": {
                  pl: "26px",
                  ml: "-19px",
                  ...backgroundOnOverlayHover,
                },
                maskImage:
                  "radial-gradient(36px 100% at left, transparent, transparent 50%, black 50%, black 100%);",
              }}
              onClick={() => onValueChanged(SceneFilter.Cad)}
            >
              {cadModelObject ?? activeCad === undefined ? (
                <CadSmallIcon sx={TOGGLE_ICON_SX} />
              ) : (
                <FaroTooltip title="Retrieving 3D model...">
                  <CircularProgress
                    sx={{
                      color: neutral[0],
                      mr: 0.5,
                    }}
                    size="1.5em"
                  />
                </FaroTooltip>
              )}
              {layout === TypeToggleButtonsLayout.Large && "3D Model"}
              {cadEnabled && (
                // This div component is intended to apply a transparent effect on the TypeToggleButton behind it
                //  in order to show the loading progress of the 3D Model.
                <Box
                  component="div"
                  position="absolute"
                  sx={{
                    right: "0",
                    width: progressWidth,
                    height: "100%",
                    bgcolor: "white",
                    opacity: "20%",
                  }}
                />
              )}
            </TypeToggleButton>
          </FaroTooltip>
        )}
      </ToggleButtonGroup>

      <PointCloudToggleMenu
        currentMainElement={currentMainElement}
        onActiveElementChanged={(id) => {
          onValueChanged(SceneFilter.PointCloud);
          onActiveElementChanged(id);
          setIsPointCloudMenuOpen(false);
        }}
        anchorEl={pointCloudButton.current}
        open={isMultiCloud && isPointCloudMenuOpen}
        onClose={() => setIsPointCloudMenuOpen(false)}
      />
      <NewFeatureOrbisFlash
        anchorEl={pointCloudButton.current}
        isMultiCloud={isMultiCloud}
      />
    </>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

type NewFeatureOrbisFlashProps = Pick<FaroPopoverProps, "anchorEl"> & {
  isMultiCloud: boolean;
};

function NewFeatureOrbisFlash({
  isMultiCloud,
  anchorEl,
}: NewFeatureOrbisFlashProps): JSX.Element | null {
  const [isNewFeature, setIsNewFeature] = useLocalStorage(
    "newFeatureOrbisFlash",
    true,
  );

  const onClose = useCallback(() => {
    setIsNewFeature(false);
  }, [setIsNewFeature]);

  return isMultiCloud && anchorEl ? (
    <FaroPopover
      dark
      open={isNewFeature}
      anchorEl={anchorEl}
      onClose={onClose}
      closeOnClickOutside={false}
      title="Support for Orbis Flash scans"
      chipLabel="New"
      description={
        <>
          Flash scans provide higher density and precision than standard Orbis
          scans. Switch between Mobile point cloud and Flash scans to get most
          out of your dataset. For information about how to create Flash scans
          with Orbis,{" "}
          <Link
            href="https://farotechnologies.mcoutput.com/faro-orbis/en-us/index.htm#flash-scans.htm"
            target="_blank"
            sx={{
              color: cyan[400],
              textDecorationColor: cyan[400],
              "&:visited": { color: cyan[200], textDecorationColor: cyan[200] },
            }}
          >
            click here
          </Link>
          .
        </>
      }
      actions={[{ children: "Got it", onClick: onClose }]}
      sx={{
        // Show popover after slight delay
        animationName: `${fadeIn}`,
        animationDuration: "0.5s",
        animationDelay: "1s",
        animationFillMode: "backwards",
      }}
    />
  ) : null;
}

type ActivePointCloudIconProps = {
  /** the currently active element in the view */
  currentMainElement?: IElement;
};

/** @returns Icon for the selected type of point cloud stream. Defaults to generic point cloud icon. */
function ActivePointCloudIcon({
  currentMainElement,
}: ActivePointCloudIconProps): JSX.Element | null {
  if (
    currentMainElement &&
    isIElementGenericPointCloudStream(currentMainElement)
  ) {
    return (
      <PointCloudStreamIcon
        pointCloudStream={currentMainElement}
        sx={TOGGLE_ICON_SX}
      />
    );
  }

  return <PointCloudIcon sx={TOGGLE_ICON_SX} />;
}
