import {
  DeleteMeasurementEventProperties,
  EventType,
  ToggleUnitOfMeasureActionSource,
} from "@/analytics/analytics-events";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { useUiOverlayContext } from "@/components/common/ui-overlay-provider";
import { useToggleUnitOfMeasure } from "@/components/common/unit-of-measure-context";
import { createAnnotationFields } from "@/components/ui/annotations/annotation-fields";
import { isExternalAnnotationData } from "@/components/ui/annotations/annotation-props";
import { createAttachments } from "@/components/ui/annotations/attachment-mutations";
import {
  CreateAnnotationForm,
  CreateAnnotationFormProps,
} from "@/components/ui/annotations/create-annotation-form";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useCurrentArea } from "@/modes/mode-data-context";
import { selectActiveMeasurement } from "@/store/measurement-tool-selector";
import {
  ComponentsToDisplay,
  Measurement,
  removeMeasurement,
  setActiveMeasurement,
  setMeasurementComponentsToDisplay,
} from "@/store/measurement-tool-slice";
import { RootState } from "@/store/store";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import {
  ToolName,
  deactivateTool,
  setAnnotationExpansion,
} from "@/store/ui/ui-slice";
import { selectCurrentUser } from "@/store/user-selectors";
import {
  selectObjectVisibility,
  selectVisibilityDistance,
} from "@/store/view-options/view-options-selectors";
import {
  ViewObjectTypes,
  setObjectVisibility,
} from "@/store/view-options/view-options-slice";
import { selectAddPolygonMutationData } from "@/tools/annotation-mutation-utils";
import { MeasuresSorter } from "@/tools/multi-point-measures/measures-sorter";
import { useUpdateVisibilityDistance } from "@/utils/use-update-visibility-distance";
import {
  UPDATE_CAMERA_MONITOR_PRIORITY,
  parseVector3,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { GUID, assert, generateGUID } from "@faro-lotv/foundation";
import {
  IElementMeasurePolygon,
  IElementSection,
  IElementTypeHint,
  isIElementGenericImgSheet,
} from "@faro-lotv/ielement-types";
import {
  fetchProjectIElements,
  selectAdvancedMarkupTemplateIds,
  selectIElementWorldPosition,
  selectRootIElement,
} from "@faro-lotv/project-source";
import {
  MutationAddMeasurePolygon,
  createMutationAddLabel,
  createMutationAddMarkup,
  createMutationAddMeasurePolygon,
} from "@faro-lotv/service-wires";
import { Dialog } from "@mui/material";
import { useFrame, useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Material, Object3D, Plane, Vector3 } from "three";
import { MeasurementValuesField } from "../annotations/annotation-renderers/measurement-values-field";
import { AnnotationVisibility } from "../annotations/annotation-utils";
import { isInsideClippingPlanes } from "../annotations/annotations-renderer";
import { MultiPointMeasureRenderer } from "./multi-point-measure-renderer";

type MeasurementsRendererProps = {
  /** The list of measurements to render */
  measurements: Measurement[];

  /** Check if there's a picking tool active */
  isToolActive?: boolean;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** When a collapsed measure is clicked, this callback is issued to move the camera towards the measure */
  onCollapsedMeasurementClicked?(point: Vector3): void;

  /** Whether depth testing should be used to render the measurement */
  depthTest?: Material["depthTest"];

  /** The render order to use for the measurement */
  renderOrder?: Object3D["renderOrder"];
};

/** @returns The renderer of the measurements stored in the app store */
export function MeasurementsRenderer({
  measurements: allMeasurements,
  isToolActive = false,
  clippingPlanes = [],
  onCollapsedMeasurementClicked,
  depthTest,
  renderOrder,
}: MeasurementsRendererProps): JSX.Element | null {
  const dispatch = useAppDispatch();

  const root = useAppSelector(selectRootIElement);
  const { unitOfMeasure, toggleUnitOfMeasure } = useToggleUnitOfMeasure(
    true,
    ToggleUnitOfMeasureActionSource.measureToolbar,
  );

  const activeMeasurement = useAppSelector(selectActiveMeasurement);
  // Computing the measurements inside the clipping planes
  const measurements = useMemo(() => {
    const filtered = allMeasurements.filter(
      (m) => !isToolActive || m.id === activeMeasurement?.id,
    );
    if (clippingPlanes.length !== 6) {
      return filtered;
    }
    const ret = new Array<Measurement>();
    for (const measurement of filtered) {
      const position = measurement.points
        .reduce((prev, next) => prev.add(parseVector3(next)), new Vector3())
        .divideScalar(measurement.points.length);
      if (isInsideClippingPlanes(position, clippingPlanes)) {
        ret.push(measurement);
      }
    }
    return ret;
  }, [activeMeasurement?.id, allMeasurements, isToolActive, clippingPlanes]);

  const measurementsPoints = useMemo(() => {
    return measurements.map((m) =>
      m.points.map((p) => new Vector3().fromArray(p)),
    );
  }, [measurements]);

  const deleteActiveMeasurement = useCallback(() => {
    if (activeMeasurement) {
      Analytics.track<DeleteMeasurementEventProperties>(
        EventType.deleteMeasurement,
        {
          via: "action bar",
        },
      );

      dispatch(
        removeMeasurement({
          elementID: activeMeasurement.parentId,
          measurementID: activeMeasurement.id,
        }),
      );
    }
  }, [activeMeasurement, dispatch]);

  const changeMeasurementComponentsToDisplay = useCallback(
    (newComponentsToDisplay: ComponentsToDisplay) => {
      if (activeMeasurement) {
        // update currentComponentsToDisplay
        dispatch(
          setMeasurementComponentsToDisplay({
            elementID: activeMeasurement.parentId,
            measurementID: activeMeasurement.id,
            componentsToDisplay: newComponentsToDisplay,
          }),
        );
      }
    },
    [activeMeasurement, dispatch],
  );

  const disableToggleMeasurmentComponents = useMemo(
    () => activeMeasurement && activeMeasurement.points.length !== 2,
    [activeMeasurement],
  );

  const camera = useThree((s) => s.camera);

  const sorter = useMemo(() => new MeasuresSorter(camera), [camera]);

  // While picking the other measurements should not be interactable
  const activeTool = useAppSelector(selectActiveTool);
  const disablePointerEvents = activeTool === ToolName.measurement;

  const [measuresVisibility, setMeasuresVisibility] = useState(
    new Array<AnnotationVisibility>(),
  );

  const visibilityDistance = useAppSelector(selectVisibilityDistance);

  useFrame(({ camera }, delta) => {
    sorter.update(
      camera,
      measurements,
      activeMeasurement?.id,
      delta,
      visibilityDistance,
      visibilityDistance,
    );
    if (sorter.dirty()) {
      // Updating React states inside useFrame leads to downgraded performances.
      // Therefore, the 'measuresVisibility' state is updated only once every
      // 'sorter.secsBeforeUpdate' seconds.
      setMeasuresVisibility(sorter.measuresVisibility.slice());
      sorter.resetDirty();
    }
  }, UPDATE_CAMERA_MONITOR_PRIORITY);

  const unselectActiveMeasurement = useCallback(() => {
    dispatch(setActiveMeasurement(undefined));
  }, [dispatch]);

  const onMeasurementClicked = useCallback(
    (measurement: Measurement) => {
      if (activeMeasurement?.id === measurement.id) {
        // The click has been executed on active measurement, so unselect the measurement
        unselectActiveMeasurement();
      } else {
        Analytics.track(EventType.selectMeasurement);
        dispatch(setActiveMeasurement(measurement));
      }
    },
    [activeMeasurement, dispatch, unselectActiveMeasurement],
  );

  // Define all variables needed to add the measurement to the project
  const { area } = useCurrentArea();
  const { handleErrorWithToast } = useErrorHandlers();
  const appStore = useAppStore();
  const currentUser = useAppSelector(selectCurrentUser);
  const projectApi = useCurrentProjectApiClient();
  const [annotationToCreate, setAnnotationToCreate] = useState<Measurement>();

  const updateVisibilityDistance = useUpdateVisibilityDistance();
  const saveMeasurement = useCallback<CreateAnnotationFormProps["onSave"]>(
    (data): Promise<void> => {
      async function createMeasurement(): Promise<void> {
        assert(
          !isExternalAnnotationData(data),
          "It's only possible to create a Sphere XG annotation from a measurement",
        );
        const {
          title,
          newAttachments,
          assignee,
          description,
          dueDate,
          status,
          tags,
        } = data;
        if (!root || !currentUser) return;
        if (!annotationToCreate) return;

        const appState = appStore.getState();

        assert(area, "Expected an area to be available");

        const mutation = createMeasurementMutation(
          appState,
          annotationToCreate,
          area,
        );
        if (!mutation) return;
        const { addMeasureMutation, parentId } = mutation;

        const templateIds = selectAdvancedMarkupTemplateIds(appState);
        assert(
          templateIds,
          "Expected project to have an advanced markup template",
        );

        const markupId = generateGUID();
        const markupFields = createAnnotationFields({
          assignee,
          status,
          dueDate,
          ...templateIds,
          markupId,
          currentUserId: currentUser.id,
          rootId: root.id,
        });

        const addMarkupMutation = createMutationAddMarkup({
          id: markupId,
          templateId: templateIds.templateId,
          rootId: root.id,
          name: title,
          description: description ?? "",
          annotationId: addMeasureMutation.newElement.id,
          markupFields,
        });

        const attachmentMutations = createAttachments(
          root.id,
          markupId,
          newAttachments,
        );

        const tagsMutations =
          tags?.map((tag) => createMutationAddLabel(markupId, tag.id)) ?? [];

        const results = await projectApi.applyMutations([
          addMeasureMutation,
          addMarkupMutation,
          ...attachmentMutations,
          ...tagsMutations,
        ]);

        if (results.some((r) => r.status !== "success")) {
          return;
        }

        setAnnotationToCreate(undefined);

        // Update the IElement tree
        await dispatch(
          fetchProjectIElements({
            fetcher: () =>
              projectApi.getAllIElements({
                // We only need to fetch the subtree starting from the targetIElement
                ancestorIds: [parentId],
              }),
          }),
        );

        // Remove the measurement from the store since it's in the project now
        dispatch(
          removeMeasurement({
            elementID: annotationToCreate.parentId,
            measurementID: annotationToCreate.id,
          }),
        );

        // Auto expand the new annotation
        dispatch(setAnnotationExpansion({ id: markupId, expanded: true }));

        // Ensure the new generated annotation is visible
        const markupPosition = selectIElementWorldPosition(markupId)(
          appStore.getState(),
        );
        updateVisibilityDistance(
          camera,
          new Vector3().fromArray(markupPosition),
        );
        dispatch(
          setObjectVisibility({
            type: ViewObjectTypes.annotations,
            visibility: true,
          }),
        );

        // Disable possible active tools to make sure the new created measurement is visible
        dispatch(deactivateTool());
      }
      return createMeasurement().catch((error) => {
        handleErrorWithToast({
          title: "Could not create annotation",
          error,
        });
      });
    },
    [
      annotationToCreate,
      appStore,
      area,
      camera,
      currentUser,
      dispatch,
      handleErrorWithToast,
      projectApi,
      root,
      updateVisibilityDistance,
    ],
  );

  const onClose = useCallback(() => setAnnotationToCreate(undefined), []);
  useCreateMeasurementForm(annotationToCreate, onClose, saveMeasurement);

  const shouldMeasurementsBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.measurements),
  );
  if (!shouldMeasurementsBeVisible) {
    return null;
  }

  return (
    <>
      {measurements.map((measurement, index) => (
        <MultiPointMeasureRenderer
          key={measurement.id}
          points={measurementsPoints[index]}
          disablePointerEvents={disablePointerEvents}
          onClick={() => onMeasurementClicked(measurement)}
          onDeleteActiveMeasurement={deleteActiveMeasurement}
          onChangeMeasurementComponentsToDisplay={
            disableToggleMeasurmentComponents
              ? undefined
              : changeMeasurementComponentsToDisplay
          }
          otherLive={disablePointerEvents}
          active={measurement.id === activeMeasurement?.id}
          visibility={measuresVisibility[index]}
          onCollapsedMeasurementClicked={(point) => {
            dispatch(setActiveMeasurement(measurement));
            onCollapsedMeasurementClicked?.(point);
          }}
          unitOfMeasure={unitOfMeasure}
          onCreateAnnotation={() => setAnnotationToCreate(measurement)}
          onToggleUnitOfMeasure={toggleUnitOfMeasure}
          isClosed={measurement.metadata.isLoop}
          depthTest={depthTest}
          renderOrder={renderOrder}
          componentsToDisplay={measurement.componentsToDisplay}
        />
      ))}
    </>
  );
}

type MutationCreationResult = {
  addMeasureMutation: MutationAddMeasurePolygon;
  parentId: GUID;
};

/**
 * Compute the mutation and all the necessary parameters to add the measurement polygon to the project
 *
 * @param appState The app state
 * @param annotationToCreate The measurement to store in the project
 * @param area The current area of the project
 * @returns The mutation to add the measurement to project and id of the parent element
 */
function createMeasurementMutation(
  appState: RootState,
  annotationToCreate: Measurement,
  area: IElementSection,
): MutationCreationResult | undefined {
  const mutationData = selectAddPolygonMutationData(
    annotationToCreate,
    area,
  )(appState);
  if (!mutationData) return;

  // Create the actual mutation
  const addMeasureMutation = createMutationAddMeasurePolygon({
    ...mutationData,
    name: annotationToCreate.metadata.name,
    isClosed: annotationToCreate.metadata.isLoop,
    typeHint: isIElementGenericImgSheet(mutationData.targetElement)
      ? IElementTypeHint.mapMeasurement
      : IElementTypeHint.spaceMeasurement,
  });

  return {
    addMeasureMutation,
    parentId: mutationData.sectionId,
  };
}

function useCreateMeasurementForm(
  measurement: Measurement | undefined,
  onClose: CreateAnnotationFormProps["onClose"],
  onSave: CreateAnnotationFormProps["onSave"],
): void {
  const annotationToCreate:
    | Pick<IElementMeasurePolygon, "points" | "isClosed">
    | undefined = useMemo(
    () =>
      measurement
        ? {
            points: measurement.points.map((p) => ({
              // TODO: Remove usage of null from the polygon point states - https://faro01.atlassian.net/browse/SWEB-4163
              state: null,
              x: p[0],
              y: p[1],
              z: p[2],
            })),
            isClosed: measurement.metadata.isLoop,
          }
        : undefined,
    [measurement],
  );

  const { setContent } = useUiOverlayContext();
  useEffect(() => {
    setContent(
      annotationToCreate ? (
        <Dialog
          open
          PaperProps={{
            sx: { p: 1, background: "transparent" },
            elevation: 0,
          }}
        >
          <CreateAnnotationForm onClose={onClose} onSave={onSave}>
            <MeasurementValuesField measurement={annotationToCreate} />
          </CreateAnnotationForm>
        </Dialog>
      ) : undefined,
    );

    return () => setContent(undefined);
  }, [annotationToCreate, onClose, onSave, setContent]);
}
