import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectAreaContents } from "@/store/area-contents-selectors";
import {
  ENTIRE_PROJECT_KEY,
  setAreaContents,
} from "@/store/area-contents-slice";
import {
  AreaContentType,
  CAPTURES_ELEMENT_TYPES,
  ROOMS_ELEMENT_TYPES,
} from "@/store/area-contents-types";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { GUID, assert } from "@faro-lotv/foundation";
import { IElement } from "@faro-lotv/ielement-types";
import {
  addAreaDataSets,
  fetchProjectIElements,
  selectAreaCaptureTreeDataSetIds,
  selectIElementsIsFetchingNeeded,
  selectIsSubtreeLoading,
} from "@faro-lotv/project-source";
import {
  DataSetAreaInfo,
  GetIElementsParams,
  ProjectApi,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import {
  MutableRefObject,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

/** After how many seconds to consider a sub-tree stale and in needs to be re-fetched */
const STALE_TIME = 300;

/** Collection of requests to get IElements */
export type IElementFetchRequests = Array<Promise<IElement[]>>;

/** String that represent a specific ProjectApi fetch request encoding the subtree parameters */
type SubTreeRequestHash = string;

/** Information about the fetching of a sub-tree */
type TreeFetchData = {
  /** True if this fetch is in progress */
  isInProgress: boolean;

  /** Timeout handle for the setTimeout used to schedule the abort of this fetch  */
  abortTimeout?: number;

  /** Date of when this fetch was successfully executed */
  lastSuccessfulFetch?: Date;
};

/** Project context data provided by the ProjectProvider */
type ProjectLoadingContext = {
  /** The authenticated client to fetch data from the ProjectAPI */
  client: ProjectApi;

  /** Map to cache of all the individual element fetches */
  elementFetches: MutableRefObject<Map<SubTreeRequestHash, TreeFetchData>>;

  /** Map to cache of all the individual parent fetches */
  parentFetches: MutableRefObject<Map<SubTreeRequestHash, TreeFetchData>>;

  /** Map to cache of all the sub-tree fetches */
  subTreeFetches: MutableRefObject<Map<SubTreeRequestHash, TreeFetchData>>;
};

export const ProjectLoadingContext = createContext<
  ProjectLoadingContext | undefined
>(undefined);

/** @returns a context that offers functionality to incrementally load areas and sub-trees of a project */
export function ProjectLoadingContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const { projectApiClient: client } = useApiClientContext();

  const elementFetches = useRef(new Map<SubTreeRequestHash, TreeFetchData>());

  const parentFetches = useRef(new Map<SubTreeRequestHash, TreeFetchData>());

  const subTreeFetches = useRef(new Map<SubTreeRequestHash, TreeFetchData>());

  // When the client changes we need to clear the cache for all the fetches as
  // they're client specific
  useEffect(() => {
    elementFetches.current.clear();
    parentFetches.current.clear();
    subTreeFetches.current.clear();
  }, [client]);

  const value = useMemo<ProjectLoadingContext>(
    () => ({
      client,
      elementFetches,
      parentFetches,
      subTreeFetches,
    }),
    [client],
  );

  return (
    <ProjectLoadingContext.Provider value={value}>
      {children}
    </ProjectLoadingContext.Provider>
  );
}

/**
 * @returns the current bound ProjectProvider context
 */
function useProjectLoadingContext(): ProjectLoadingContext {
  const ctx = useContext(ProjectLoadingContext);

  assert(
    ctx,
    "useProjectProviderContext can be used only inside a ProjectProvider",
  );

  return ctx;
}

/**
 * @returns The current ProjectApi client instance used to fetch project data or undefined in a demo project
 */
export function useCurrentProjectApiClient(): ProjectApi {
  return useProjectLoadingContext().client;
}

/**
 * Fetch a project api sub-tree from the backend
 *
 * @param id of the node to download the subtrees for
 * @returns true if the loading is in progress
 */
export function useProjectSubTree(id: GUID | undefined): boolean {
  return useProjectSubTrees(id ? [id] : undefined);
}

/**
 * @param areaId of the area to load the BI sub-tree and all the datasets
 * @returns true if the area or some datasets inside the area are still loading
 */
export function useLoadProjectArea(areaId: GUID | undefined): boolean {
  const dataSetIds = useAppSelector((state) =>
    selectAreaCaptureTreeDataSetIds(state, areaId),
  );

  const isAreaLoading = useProjectSubTree(areaId);
  const areDataSetLoading = useProjectSubTrees(dataSetIds);
  const isVolumeQueryRunning = useLoadAreaDataSets(areaId);
  const areAreaContentsLoading = useLoadAreaContents(areaId, isAreaLoading);

  return (
    isVolumeQueryRunning ||
    isAreaLoading ||
    areDataSetLoading ||
    areAreaContentsLoading
  );
}

/**
 * Load from the backend all the datasets that are contained in an area volume
 *
 * @param areaId to query the volume for datasets. If undefined, the contents for the entire project are loaded.
 * @returns true while the volume query is running
 */
function useLoadAreaDataSets(areaId: GUID | undefined): boolean {
  const [isLoading, setIsLoading] = useState(!!areaId);
  const projectApi = useCurrentProjectApiClient();
  const dispatch = useAppDispatch();

  const shouldUseNewVolumeQuery = useAppSelector(
    selectHasFeature(Features.AreaNavigation),
  );

  useEffect(() => {
    const ac = new AbortController();

    let loadingPromise: Promise<unknown>;

    if (shouldUseNewVolumeQuery) {
      loadingPromise = Promise.allSettled([
        projectApi
          .getAllIElementsByVolume({
            areaId,
            elementTypes: CAPTURES_ELEMENT_TYPES,
            signal: ac.signal,
          })
          .then((data) => {
            dispatch(
              setAreaContents({
                areaContentsKey: areaId ?? ENTIRE_PROJECT_KEY,
                type: AreaContentType.captures,
                elements: data,
              }),
            );
          }),
        projectApi
          .getAllIElementsByVolume({
            areaId,
            elementTypes: ROOMS_ELEMENT_TYPES,
            signal: ac.signal,
          })
          .then((data) => {
            dispatch(
              setAreaContents({
                areaContentsKey: areaId ?? ENTIRE_PROJECT_KEY,
                type: AreaContentType.rooms,
                elements: data,
              }),
            );
          }),
      ]);
    } else {
      // The old navigation can't have no area set. This should only happen in empty projects.
      if (!areaId) {
        return;
      }

      loadingPromise = projectApi
        .queryAreaVolume(areaId, ac.signal)
        .then((dataSets: DataSetAreaInfo[]) => {
          dispatch(addAreaDataSets({ areaId, dataSets }));
        });
    }

    loadingPromise.finally(() => setIsLoading(false));

    return () => {
      setIsLoading(false);
      ac.abort();
    };
  }, [areaId, dispatch, projectApi, shouldUseNewVolumeQuery]);

  return isLoading;
}

/**
 * Fetches the area contents that are not loaded yet
 *
 * @param areaId the area to load. If undefined, the contents for the entire project are loaded.
 * @param isAreaLoading indicates whether the area is currently loading its subtree
 * @returns true if the loading is in progress
 */
function useLoadAreaContents(
  areaId: GUID | undefined,
  isAreaLoading: boolean,
): boolean {
  const dispatch = useAppDispatch();
  const { getState } = useAppStore();
  const { handleErrorWithPage } = useErrorHandlers();
  const ctx = useProjectLoadingContext();

  const [isLoading, setIsLoading] = useState(true);

  const captures = useAppSelector(
    selectAreaContents(areaId ?? ENTIRE_PROJECT_KEY, AreaContentType.captures),
  );
  const rooms = useAppSelector(
    selectAreaContents(areaId ?? ENTIRE_PROJECT_KEY, AreaContentType.rooms),
  );

  const allIds = useMemo(
    () =>
      captures.map((c) => c.elementId).concat(rooms.map((r) => r.elementId)),
    [captures, rooms],
  );

  const {
    elementsThatNeedFetching,
    elementsThatNeedParentFetching,
    elementsThatNeedChildFetching,
  } = useAppSelector((state) => selectIElementsIsFetchingNeeded(state, allIds));

  // Updates the internal loading state. Done as a callback to manually update the state when the fetching promises finish
  const updateLoadingState = useCallback(() => {
    // Manually update the state here, because a callback dependency would provide the value from last render, not the current app state
    const {
      elementsThatNeedFetching,
      elementsThatNeedParentFetching,
      elementsThatNeedChildFetching,
    } = selectIElementsIsFetchingNeeded(getState(), allIds);

    // Check that all required fetches are started (= defined) and not in progress anymore
    const hasElementFetchInProgress = !elementsThatNeedFetching.every(
      (id) => ctx.elementFetches.current.get(id)?.isInProgress === false,
    );
    const hasParentFetchInProgress = !elementsThatNeedParentFetching.every(
      (id) => ctx.parentFetches.current.get(id)?.isInProgress === false,
    );
    const hasChildFetchInProgress = !elementsThatNeedChildFetching.every(
      (id) => ctx.subTreeFetches.current.get(id)?.isInProgress === false,
    );

    setIsLoading(
      hasElementFetchInProgress ||
        hasParentFetchInProgress ||
        hasChildFetchInProgress,
    );
  }, [
    getState,
    allIds,
    ctx.elementFetches,
    ctx.parentFetches,
    ctx.subTreeFetches,
  ]);

  // Starts an element/parent/child fetch for the ids that are not requested yet
  const fetchIfNotInProgress = useCallback(
    (
      // The elements to fetch
      idsToFetch: GUID[],
      // The map storing the current request states for the current request type
      fetchDataMap: Map<string, TreeFetchData>,
      // The query filter to use to fetch the iElements
      iElementFetchKey: keyof Pick<
        GetIElementsParams,
        "descendantIds" | "ancestorIds" | "ids"
      >,
    ) => {
      if (idsToFetch.length) {
        const notStartedFetches = idsToFetch.filter(
          (id) => !fetchDataMap.has(id),
        );

        if (notStartedFetches.length) {
          const fetchData: TreeFetchData = {
            isInProgress: true,
            abortTimeout: undefined,
            lastSuccessfulFetch: undefined,
          };

          for (const id of notStartedFetches) {
            fetchDataMap.set(id, fetchData);
          }

          const now = new Date();
          const abortController = new AbortController();

          dispatch(
            fetchProjectIElements({
              fetcher: () =>
                ctx.client.getAllIElements({
                  signal: abortController.signal,
                  [iElementFetchKey]: notStartedFetches,
                }),
              loadingIds: notStartedFetches,
            }),
          )
            .then((elements) => {
              // If fetch is successful store fetch time in the context cache
              fetchData.lastSuccessfulFetch = now;
              return elements;
            })
            .finally(() => {
              fetchData.isInProgress = false;
              updateLoadingState();
            })
            .catch(handleErrorWithPage);
        }
      }
    },
    [ctx.client, dispatch, handleErrorWithPage, updateLoadingState],
  );

  // Starts the required fetches to load the area contents
  useLayoutEffect(() => {
    if (isAreaLoading) {
      // Avoid duplicate requests for elements that are already returned when the area is loaded
      return;
    }

    fetchIfNotInProgress(
      elementsThatNeedFetching,
      ctx.elementFetches.current,
      // Also fetch the parents to save one request that we'll have to make in most cases to unblock the ui
      "descendantIds",
    );

    fetchIfNotInProgress(
      elementsThatNeedParentFetching,
      ctx.parentFetches.current,
      "descendantIds",
    );

    fetchIfNotInProgress(
      elementsThatNeedChildFetching,
      ctx.subTreeFetches.current,
      "ancestorIds",
    );

    updateLoadingState();
  }, [
    isAreaLoading,
    elementsThatNeedFetching,
    elementsThatNeedParentFetching,
    elementsThatNeedChildFetching,
    ctx,
    dispatch,
    fetchIfNotInProgress,
    updateLoadingState,
    handleErrorWithPage,
  ]);

  return isLoading;
}

/**
 * Fetch a set of project api sub-trees from the backend
 *
 * @param ids of the nodes to download the subtrees for
 * @returns true if the loading is in progress
 */
function useProjectSubTrees(ids: GUID[] | undefined): boolean {
  const dispatch = useAppDispatch();
  const { handleErrorWithPage } = useErrorHandlers();
  const ctx = useProjectLoadingContext();
  // On the first render, the fetch has not started yet, but it needs to be indicated that the loading is in progress
  const [hasStarted, setHasStarted] = useState(false);

  useLayoutEffect(() => {
    // Skip the fetch if there are no ids
    if (!ids?.length) {
      return;
    }
    const now = new Date();

    // Compute the hash for this specific request
    const fetchId = ids.join("-");

    const { canSkip, lastValidFetch } = checkFetchStatus(fetchId, ctx, now);
    if (canSkip) {
      return;
    }

    // Create an object to store information on the new fetch
    const fetchData: TreeFetchData = {
      isInProgress: true,
      abortTimeout: undefined,
      lastSuccessfulFetch: undefined,
    };
    ctx.subTreeFetches.current.set(fetchId, fetchData);

    // Here we need to start a new fetch
    const abortController = new AbortController();

    dispatch(
      fetchProjectIElements({
        fetcher: () =>
          ctx.client.getAllIElements({
            signal: abortController.signal,
            ancestorIds: ids,
            changedAfter: lastValidFetch,
          }),
        loadingIds: ids,
      }),
    )
      .then((elements) => {
        // If fetch is successful store fetch time in the context cache
        fetchData.lastSuccessfulFetch = now;
        return elements;
      })
      .finally(() => {
        fetchData.isInProgress = false;
      })
      .catch(handleErrorWithPage);

    setHasStarted(true);

    return () => {
      // When unmount schedule abort after a second so if we re-fetch this same request soon
      // we just keep the previous fetch alive (thanks React double mounts)
      fetchData.abortTimeout = window.setTimeout(() => {
        abortController.abort.bind(abortController);
      }, 1000);
    };
  }, [ctx, dispatch, ids, handleErrorWithPage]);

  const isSubtreeLoading = !!useAppSelector((state) =>
    ids?.some((id) => selectIsSubtreeLoading(id)(state)),
  );

  // If the passed ids are empty we consider the loading finished
  return !!ids?.length && (!hasStarted || isSubtreeLoading);
}

/**
 * Check if a new fetch can be skipped
 *
 * @param hash The hash of the new subTree fetch
 * @param ctx The project fetching context
 * @param now The current timestamp
 * @returns A boolean indicating if we can skip this fetch and the last valid fetch for this query if it exists
 */
function checkFetchStatus(
  hash: SubTreeRequestHash,
  ctx: ProjectLoadingContext,
  now: Date,
): { canSkip: boolean; lastValidFetch?: Date } {
  // If the data was already fetched and is not stale skip this fetch
  const fetchData = ctx.subTreeFetches.current.get(hash);
  if (!fetchData) {
    return { canSkip: false };
  }

  const { lastSuccessfulFetch } = fetchData;
  if (
    lastSuccessfulFetch &&
    now.getSeconds() - lastSuccessfulFetch.getSeconds() < STALE_TIME
  ) {
    return { canSkip: true, lastValidFetch: lastSuccessfulFetch };
  }

  // If the same fetch is already in progress skip this fetch
  if (fetchData.isInProgress) {
    // If the already in flight fetched was scheduled to abort
    // stop the timeout and keep it alive
    if (fetchData.abortTimeout) {
      clearTimeout(fetchData.abortTimeout);
    }
    return { canSkip: true, lastValidFetch: lastSuccessfulFetch };
  }

  return { canSkip: false, lastValidFetch: lastSuccessfulFetch };
}
