import { PointCloudObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  FilteredRenderPass,
  NormalizeOpacityPass,
  SubScene,
} from "@faro-lotv/app-component-toolbox";
import { useEffect, useMemo } from "react";
import { Object3D } from "three";
import {
  selectPointCloudStreamIdsForHoveredEntity,
  selectPointCloudStreamIdsForSelectedEntity,
} from "../store/data-preparation-ui/data-preparation-ui-selectors";
import { MultiCloudRenderingPolicy } from "./multi-cloud-rendering-policy";

type RevisionRenderingPipelineProps = {
  /** The point clouds to render in the pipeline. */
  pointCloudObjects: PointCloudObject[];
};

/**
 * @returns A custom rendering pipeline for more efficient point cloud rendering.
 */
export function RevisionRenderingPipeline({
  pointCloudObjects,
}: RevisionRenderingPipelineProps): JSX.Element {
  const renderingPolicy = useMemo(
    () => new MultiCloudRenderingPolicy(pointCloudObjects),
    [pointCloudObjects],
  );

  const hoveredPointClouds = useAppSelector(
    selectPointCloudStreamIdsForHoveredEntity,
  );
  const selectedPointClouds = useAppSelector(
    selectPointCloudStreamIdsForSelectedEntity,
  );
  // Invalidate scene when point cloud colors change
  useEffect(() => {
    renderingPolicy.invalidateScene();
  }, [hoveredPointClouds, selectedPointClouds, renderingPolicy]);

  return (
    <EffectPipelineWithSubScenes>
      {/* Efficiently render point clouds separately */}
      <SubScene
        filter={(obj: Object3D) => isObjPointCloudPoint(obj)}
        renderingPolicy={renderingPolicy}
        transparentBackground={true}
      />
      <NormalizeOpacityPass />
      {/* All other objects */}
      <FilteredRenderPass
        filter={(obj: Object3D) => !isObjPointCloudPoint(obj)}
        clear={false}
        clearDepth={false}
      />
      <CopyToScreenPass />
    </EffectPipelineWithSubScenes>
  );
}
