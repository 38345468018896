import {
  ButtonProps,
  IconButton,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { isString } from "es-toolkit";
import {
  CSSProperties,
  HTMLAttributeAnchorTarget,
  ReactNode,
  forwardRef,
  useMemo,
} from "react";
import { neutral } from "../colors";

export enum FaroIconButtonSizes {
  xs = "xs",
  s = "s",
  m = "m",
  l = "l",
  xl = "xl",
}

export type FaroIconButtonMargins = 0 | 0.5 | 1 | 1.5;

type ColorType = NonNullable<CSSProperties["color"]>;

/** The type for the color prop passed to the component. It could be a string or hex color, or even a function that returns a color */
type ColorProp = ColorType | ((theme: Theme) => ColorType);

export type FaroIconButtonProps = Omit<
  ButtonProps,
  "variant" | "size" | "startIcon" | "color"
> & {
  /**
   * Size variants for this button
   *
   * @default "M"
   */
  size?: `${FaroIconButtonSizes}`;

  /**
   * Margin variants for this button.
   * The default values change based on the button size.
   *
   * @default "size xs = 0, size s | m = 1, size l | xl = 1.5"
   */
  margin?: FaroIconButtonMargins;

  /**
   * Optional color to apply to the image
   */
  color?: ColorProp;

  /**
   * Optional color to apply to the image on hover and on focus
   *
   * @default primary.main
   */
  hoverColor?: ColorProp;

  /**
   * Optional background color to apply to the ripple effect on hover and on pressed.
   * This must not contain the opacity value otherwise the ripple color will be static.
   * For ripple, opacity is applied internally with the value 10%.
   *
   * @default neutral[500]
   */
  rippleColor?: ColorProp;

  /**
   * Define to true to disable this button
   *
   * @default false
   */
  disabled?: boolean;

  /** Content of the button */
  children: ReactNode;

  /** Extra style for this button */
  sx?: ButtonProps["sx"];

  /** The target to which the IconButton should navigate to */
  target?: HTMLAttributeAnchorTarget;

  /** The link to which the IconButton should navigate to */
  href?: string;

  /**
   * Define if the button is in a pressed state.
   * When true, the button will have a different background color to indicate it is pressed.
   *
   * @default false
   */
  pressed?: boolean;
};

export const FaroIconButton = forwardRef<
  HTMLButtonElement,
  FaroIconButtonProps
>(function FaroIconButton(
  {
    size = FaroIconButtonSizes.m,
    margin,
    children,
    color,
    hoverColor = "primary.main",
    rippleColor = neutral[500],
    disabled = false,
    pressed = false,
    sx,
    ...rest
  }: FaroIconButtonProps,
  ref,
): JSX.Element | null {
  const theme = useTheme();
  const sizeStyles = SIZE_STYLE_MAP[size];

  // Take the ripple color from the theme, if available, otherwise use it directly and the add the opacity.
  // This allows to pass a value to this component with the type: string (hex or color in theme)
  // or function that returns a string (color in theme).
  const rippleColorWithOpacity = useMemo<ColorType>(() => {
    let color;

    // If the rippleColor prop is a function, use the theme to retrieve the color
    if (typeof rippleColor === "function") {
      color = rippleColor(theme);
    } else if (isString(rippleColor)) {
      // Check if the rippleColor is present in the theme's palette and take it from there if available
      if (Object.keys(theme.palette).includes(rippleColor)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore The check above confirms that rippleColor is a key of theme palette
        color = theme.palette[rippleColor];
      }
    }

    // rippleColor was not part of the theme but it could still be valid
    return `${color ?? rippleColor}26`;
  }, [rippleColor, theme]);

  return (
    <IconButton
      ref={ref}
      sx={[
        {
          display: "flex",
          p: 0,
          // Set the button opacity to half if the button is disabled
          opacity: disabled ? "50%" : "100%",
          // The first child will be the image
          [">:first-of-type"]: {
            // Font size will affect the image container size, it's set to 0 so that the container it's the size of the image
            fontSize: 0,
            // Apply custom color of the image, if defined, or default one
            color: color ?? (pressed ? rippleColorWithOpacity : neutral[800]),
            ...sizeStyles,
            // Apply margin to the button
            margin,
          },
          // Change background color on hover
          "&:hover": {
            backgroundColor: rippleColorWithOpacity,
            // The first child will be the image
            [">:first-of-type"]: {
              // Change the color of the image when hovering the button
              color: hoverColor,
            },
          },
          "&& .MuiTouchRipple-root": {
            // Make the ripple effect as big as the button
            width: "100%",
            height: "100%",
            margin: 0,
          },
          // Change ripple color when the button is pressed
          "&& .MuiTouchRipple-child": {
            backgroundColor: `${rippleColor}3F`,
          },
          // Add a border when the button is focused
          "&:focus-visible": {
            border: "solid 2px black",
            // When the button is focused also change the color of the image
            [">:first-of-type"]: {
              color: hoverColor,
            },
          },
          backgroundColor: pressed ? rippleColorWithOpacity : undefined,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
      disabled={disabled}
    >
      {children}
    </IconButton>
  );
});

// Specific style for each button size as defined in the FARO Design System
const SIZE_STYLE_MAP: Record<FaroIconButtonSizes, SxProps<Theme>> = {
  [FaroIconButtonSizes.xs]: {
    width: "1rem",
    height: "1rem",
    m: 0.5,
  },
  [FaroIconButtonSizes.s]: {
    width: "1.25rem",
    height: "1.25rem",
    m: 1,
  },
  [FaroIconButtonSizes.m]: {
    width: "1.5rem",
    height: "1.5rem",
    m: 1,
  },
  [FaroIconButtonSizes.l]: {
    width: "2rem",
    height: "2rem",
    m: 1.5,
  },
  [FaroIconButtonSizes.xl]: {
    width: "2.5rem",
    height: "2.5rem",
    m: 1.5,
  },
};
